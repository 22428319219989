import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  Paper,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import PageHeader from '../components/smallComponents/PageHeader';

export default function ViewAnswerPaper() {
  const { id } = useParams();
  const [answerPaper, setAnswerPaper] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getAnswerPaper = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/answer`, { params: { id } });
      console.log(res.data);
      if (!res.data.isError) {
        setAnswerPaper(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAnswerPaper();
  }, [id]);

  return (
    <Container maxWidth="XL">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack marginBottom={0.5} direction="row" justifyContent={'space-between'}>
            <PageHeader title={answerPaper.questionPapertitle} />
          </Stack>
          <Paper elevation={3} style={{ padding: 16 }}>
            <Typography variant="h4" gutterBottom>
              {answerPaper.questionPapertitle}
            </Typography>
            <Box my={2}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography>Candidate Name :</Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{answerPaper.candidateName}</Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography>Candidate Email: </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{answerPaper.candidateEmail}</Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography>Question Paper Title: </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{answerPaper.questionPapertitle}</Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography>Total Marks: </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{answerPaper.totalMarks}</Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography>Secured Marks: </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{answerPaper.securedMarks}</Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography>Is Finished: </Typography>
                <Typography sx={{ fontWeight: 'bold' }}> {answerPaper.isFinished ? 'Yes' : 'No'}</Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography>Status </Typography>
                <Typography sx={{ fontWeight: 'bold' }}> {answerPaper.isBlocked ? 'Yes' : 'No'}</Typography>
              </Stack>
            </Box>
            {answerPaper.sections.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                <Typography variant="h5" gutterBottom>
                  {section.title || `Section ${sectionIndex + 1}`}
                </Typography>
                {section.questions.map((q) => {
                  const question = q.questionId;
                  let statusIcon;

                  if (q.status === 'CORRECT') {
                    statusIcon = <CheckCircleIcon color="success" />;
                  } else if (q.status === 'INCORRECT') {
                    statusIcon = <CancelIcon color="error" />;
                  } else {
                    statusIcon = <HelpIcon color="action" />;
                  }

                  return (
                    <Box key={question._id} mb={3}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1">{question.question}</Typography>
                        {statusIcon}
                      </Box>
                      {question.options.map((option, index) => (
                        <div key={index}>
                          {question.optionType === 'RADIO' ? (
                            <FormControlLabel
                              control={<Radio disabled checked={q.userAnswer.includes(option)} />}
                              label={option}
                            />
                          ) : (
                            <FormControlLabel
                              control={<Checkbox disabled checked={q.userAnswer.includes(option)} />}
                              label={option}
                            />
                          )}
                        </div>
                      ))}
                      (Mark: {q.userMark})
                    </Box>
                  );
                })}
              </div>
            ))}
          </Paper>
        </>
      )}
    </Container>
  );
}
