import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  Chip,
  Box,
  Avatar,
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filter/SalesStaffPerformanceFilter';

// components
import Scrollbar from './Scrollbar';

const rfqStatusOrder = ['Pending', 'Ongoing', 'Decision Pending', 'Interested', 'Not Interested', 'Regret'];

export default function SalesStaffPerformance() {
  const [logData, setLogData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [logCount, setLogCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getLogs(temp);
  };

  const getLogs = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/lead/performance-report`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLogCount(res.data.data.maxRecords);
          setLogData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getLogsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/lead/performance-report`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLogCount(res.data.data.maxRecords);
          setLogData(res.data.data.records);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.salesStaff) {
      temp.salesStaff = filter.salesStaff;
    }
    if (filter.salesStaff) {
      temp.salesStaff = filter.salesStaff;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }

    setOptions(temp);
    setPages(0);
    getLogs(temp);
  };

  useEffect(() => {
    getLogs(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction="row" justifyContent="end" alignItems="center">
            {/* <Stack direction="row" spacing={2} padding={2}>
              <SearchStyle
                value={name}
                onChange={onSearch}
                placeholder="Search Material Name..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                endAdornment={
                  name.length > 0 && (
                    <Icon
                      onClick={() => {
                        setName('');
                        setOptions({ ...options, materialName: '' });
                        getLogsWithoutLoading({ ...options, materialName: '' });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Close />
                    </Icon>
                  )
                }
              />
            </Stack> */}
            <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
              <Filter applyFilters={applyFilters} />
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Sales Staff</TableCell>
                    <TableCell>Sales Manager</TableCell>
                    {rfqStatusOrder.map((item, id) => (
                      <TableCell key={id}>{item}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logData?.map((item, id) => (
                    <TableRow key={id}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      <TableCell>
                        {' '}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={
                              (item?.salesStaffPic && process.env.REACT_APP_API_URL + item.salesStaffPic) ||
                              'https://www.w3schools.com/howto/img_avatar.png'
                            }
                            sx={{ width: 40, height: 40 }}
                          />
                          <Box sx={{ marginLeft: 1 }}>
                            <Typography variant="subtitle2">{item?.salesStaff}</Typography>
                            <Typography variant="caption">{item.salesStaffEmail}</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={
                              (item?.managerData?.profilePic &&
                                process.env.REACT_APP_API_URL + item.managerData?.profilePic) ||
                              'https://www.w3schools.com/howto/img_avatar.png'
                            }
                            sx={{ width: 40, height: 40 }}
                          />
                          <Box sx={{ marginLeft: 1 }}>
                            <Typography variant="subtitle2">{item?.managerData?.fullname}</Typography>
                            <Typography variant="caption">{item.managerData?.email}</Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      {rfqStatusOrder.map((status, id) => {
                        const temp = item?.leadCountByStatus?.find((item) => item.name === status);
                        if (temp) {
                          return <TableCell key={id}>{temp.count}</TableCell>;
                        }
                        return <TableCell key={id}>0</TableCell>;
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              const temp = { ...options, size: e.target.value, page: 0 };
              setPages(0);
              setOptions(temp);
              getLogs(temp);
            }}
            component={'div'}
            count={logCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}
