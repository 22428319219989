import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function NavigateBack() {
  const navigate = useNavigate();
  return (
    <IconButton size="large" onClick={() => navigate(-1)}>
      <ArrowBack sx={{ color: 'primary.main', width: '32px', height: '32px' }} />
    </IconButton>
  );
}

export default NavigateBack;
