import * as Yup from 'yup';
import axios from 'axios';

import { Stack, Button, TextField, Grid } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

export default function EmployeeEditForm({ employee, getEmployee, setEditMode }) {
  const memberSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .max(20, 'Password must be at most 20 characters'),
  });

  const formik = useFormik({
    initialValues: {
      id: employee._id,
      fullname: employee.fullname,
      email: employee.email,
      password: '',
    },
    validationSchema: memberSchema,
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/employee`, { ...values });
        if (!res.data.isError) {
          setEditMode(false);
          getEmployee();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getEmployee();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fullname"
              {...getFieldProps('fullname')}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Grid>
        </Grid>
        <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" spacing={2}>
          <Button variant="contained" onClick={() => setEditMode(false)}>
            Cancel
          </Button>
          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
