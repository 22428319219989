import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  Paper,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/smallComponents/PageHeader';

export default function ViewQuestionPaper() {
  const { id } = useParams();
  const [questionPaper, setQuestionPaper] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getQuestionPaper = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/questionPaper`, { params: { id } });
      if (!res.data.isError) {
        setQuestionPaper(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getQuestionPaper();
  }, [id]);

  return (
    <Container maxWidth="xl">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {' '}
          <Stack marginBottom={0.5} direction="row" justifyContent={'space-between'}>
            <PageHeader title={questionPaper?.title} />
          </Stack>
          <Paper elevation={3} style={{ padding: 16 }}>
         

            {questionPaper.sections.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                <Typography variant="h5" gutterBottom>
                  {section.title || `Section ${sectionIndex + 1}`}
                </Typography>
                {section.questions.map((q) => {
                  const question = q.questionId;
                  return (
                    <Box key={question._id} mb={3}>
                      <Typography variant="body1">{question.question}</Typography>
                      {question.options.map((option, index) => (
                        <div key={index}>
                          {question.optionType === 'RADIO' ? (
                            <FormControlLabel
                              control={<Radio disabled checked={question.answer.includes(option)} />}
                              label={option}
                            />
                          ) : (
                            <FormControlLabel
                              control={<Checkbox disabled checked={question.answer.includes(option)} />}
                              label={option}
                            />
                          )}
                        </div>
                      ))}
                    </Box>
                  );
                })}
              </div>
            ))}
          </Paper>
        </>
      )}
    </Container>
  );
}
