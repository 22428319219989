import { ArrowBack } from '@mui/icons-material';
import { Box, Card, CardHeader, CircularProgress, Container, IconButton, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerBasicInfo from '../components/CustomerBasicInfo';
import Page from '../components/Page';
import CustomerDetailPageTabs from '../components/cardTabs/CustomerDetailPageTabs';

export default function ViewCustomer() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState({});

  const getCustomer = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/customer/one`, {
        params: {
          id,
        },
      });
      console.log('customer', res);
      setCustomer(res.data.data.customer);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <Page title="View Customer">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <>
            <Stack direction={'column'} spacing={2}>
              <CustomerBasicInfo customer={customer} getCustomer={getCustomer} />
              <CustomerDetailPageTabs customer={customer} />
            </Stack>
          </>
        </Container>
      )}
    </Page>
  );
}
