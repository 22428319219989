import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
// material
import TabCard from '../smallComponents/TabCard';

// components

export default function CustomerDetailPageTabs({ customer }) {


 

  return (
    <>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={2.4}>
            <TabCard
              title="Leads"
              color="success"
              icon={'mdi:leads-outline'}
              navigate={`/dashboard/crm/leads?customerId=${customer?._id}`}
            />
          </Grid>
         
        </Grid>
      </>
    </>
  );
}
