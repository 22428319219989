import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { CloseSharp } from '@mui/icons-material';

export default function EditTestimonial({item, handleClose }) {
  const matches = useMediaQuery('(min-width:900px)');
  const open = true;
  const [imageError, setImageError] = useState(false);
  const FILE_SIZE = 1 * 1024 * 1024;
  const SUPPORTED_FORMATS = ['jpg', 'jpeg', 'png'];
  const testimonialSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required').min(9, 'Description is required'),
    // image: Yup.mixed().test(
    //   'fileSize',
    //   'File too large',
    //   (value) => (value && value.size <= FILE_SIZE) || (value === undefined && true)
    // ).test('fileFormat', 'Unsupported Format', (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });

  const formik = useFormik({
    initialValues: {
      id: item?._id,
      title: item?.title,
      description: item?.description,
      isBlocked: item?.isBlocked,
    },
    // validationSchema: testimonialSchema,
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/testimonial`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        handleClose(res.data.message);
      } catch (error) {
        console.log(error);
        handleClose(error.data.response.message, true)
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  // useEffect(() => {
  //   if (values.image) {
  //     setImageError(false);
  //   }
  // }, [values.image]);

  return (
    <Dialog open={open} fullScreen={!matches} onClose={handleClose}>
      <Box sx={{ padding: '0px 20px 20px 20px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Edit Testimonial
          </Typography>
          <IconButton sx={{margin:"-20px -10px 0px 0px"}} onClick={handleClose}>
            <CloseSharp />
          </IconButton>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Title"
                  fullWidth
                  sx={{ width: '100%' }}
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Description"
                  fullWidth
                  sx={{ width: '100%' }}
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between" spacing={1}>
                  <TextField
                    type="file"
                    label="Image"
                    fullWidth
                    inputProps={{
                      accept: 'image/*',
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      formik.setFieldValue('image', event.target.files[0]);
                    }}
                  />
                  <TextField
                    select
                    label="Status"
                    fullWidth
                    {...getFieldProps('isBlocked')}
                    error={Boolean(touched.isBlocked && errors.isBlocked)}
                    helperText={touched.isBlocked && errors.isBlocked}
                  >
                    <MenuItem value={'true'}>BLOCKED</MenuItem>
                    <MenuItem value={'false'}>ACTIVE</MenuItem>
                  </TextField>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                  <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>
    </Dialog>
  );
}
