import { useEffect, useState } from 'react';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import Iconify from '../Iconify';
import { CustomButton } from '../../theme/CustomButton';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [categories, setCategories] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getcategories = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/category/contactus`);
      console.log('categories', res);
      setCategories(res.data.data.categories);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getcategories();
  }, []);

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                id: '',
                fullname: '',
                email: '',
                phone: '',
                startDate: '',
                endDate: '',
                category: '',
                location: '',
                budget: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack
                    spacing={1.5}
                    direction="column"
                    sx={{ minWidth: { lg: '400px', md: '400px', sm: '400px', xl: '400px' } }}
                  >
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      <TextField
                        fullWidth
                        id="id"
                        name="id"
                        label="Record ID"
                        value={values.id}
                        onChange={handleChange}
                      />
                      <TextField
                        fullWidth
                        id="fullname"
                        name="fullname"
                        label="Fullname"
                        value={values.fullname}
                        onChange={handleChange}
                      />
                    </Stack>

                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      <TextField
                        fullWidth
                        id="phone"
                        name="phone"
                        label="Phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <TextField
                        type="text"
                        select
                        id="category"
                        name="category"
                        label="Category"
                        fullWidth
                        sx={{ width: '100%' }}
                        onChange={handleChange}
                      >
                        {categories?.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item?._id}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Stack>
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      <TextField
                        fullWidth
                        id="location"
                        name="location"
                        label="Location"
                        value={values.location}
                        onChange={handleChange}
                      />
                      <TextField
                        fullWidth
                        id="budget"
                        name="budget"
                        label="Budget"
                        value={values.budget}
                        onChange={handleChange}
                      />
                    </Stack>

                    <Stack direction={'row'} spacing={2} alignItems="center">
                      <TextField
                        type="date"
                        name="startDate"
                        label="Start Date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <p style={{ paddingTop: '15px' }}>to</p>
                      <TextField
                        type="date"
                        name="endDate"
                        fullWidth
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>

                    <CustomButton variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </CustomButton>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
