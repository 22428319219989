import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import axios from 'axios';

const Popup1 = (props) => {
  const deleteLead = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/lead/delete`, {
        id: props.item._id,
      })
      .then((res) => {
        props.handleCloseDelete(true, res.data.message);
      })
      .catch((err) => {
        props.handleCloseDelete(false, err.response.data.message);
      });
  };
  const open = true;
  return (
    <>
      <Dialog onClose={() => props.handleCloseDelete(false)} open={open}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Are you sure you want to delete ${props?.item?.fullname}?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleCloseDelete(false)}>Cancel</Button>
          <Button onClick={deleteLead}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Popup1;
