// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const dashboardConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
    paths: ['dashboard#welcome'],
  },
  {
    title: 'employees',
    path: '/dashboard/employees',
    icon: getIcon('mdi:account-group-outline'),
    paths: ['general#employee'],
  },
  {
    title: 'customers',
    path: '/dashboard/crm/customers',
    icon: getIcon('streamline:information-desk-customer-solid'),
    paths: ['crm#customers'],
  },
];

const crmConfig = [
  {
    title: 'sales managers',
    path: '/dashboard/crm/salesManagers',
    icon: getIcon('mdi:account-tie'),
    paths: ['crm#salesManagers'],
  },
  {
    title: 'sales staffs',
    path: '/dashboard/crm/salesStaffs',
    icon: getIcon('ri:customer-service-2-fill'),
    paths: ['crm#salesStaffs'],
  },
  {
    title: 'leads',
    path: '/dashboard/crm/leads',
    icon: getIcon('mdi:leads'),
    paths: ['crm#leads'],
  },
  {
    title: 'reports',
    path: '/dashboard/crm/reports',
    icon: getIcon('eva:file-text-fill'),
    paths: ['crm#reports'],
  },
  {
    title: 'settings',
    path: '/dashboard/crm/settings',
    icon: getIcon('eva:settings-2-fill'),
    paths: ['crm#settings'],
  },
];

const mailConfig = [
  {
    title: 'demo',
    path: '/dashboard/demo',
    icon: getIcon('carbon:demo'),
    paths: ['mails#demo'],
  },
  {
    title: 'contact Us',
    path: '/dashboard/contactUs',
    icon: getIcon('ic:outline-contact-phone'),
    paths: ['mails#contactUs'],
  },
  {
    title: 'chat',
    path: '/dashboard/chat',
    icon: getIcon('ic:baseline-chat'),
    paths: ['mails#chat'],
  },
];

const hiringConfig = [ 
  {
    title: 'Hiring',
    path: '/dashboard/job',
    icon: getIcon('ic:baseline-work'),
    paths: ['jobs#job'],
  },
  {
    title: 'resumes',
    path: '/dashboard/resumes',
    icon: getIcon('pepicons:cv'),
    paths: ['resumes#resume'],
  },
];

const CmsConfig = [
  {
    title: 'blogs',
    path: '/dashboard/blog',
    icon: getIcon('mdi:blog-outline'),
    paths: ['blogs#blog'],
  },
  {
    title: 'testimonials',
    path: '/dashboard/testimonial',
    icon: getIcon('dashicons:testimonial'),
    paths: ['testimonials#testimonial'],
  },
];

const settingsConfig = [
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: getIcon('eva:settings-2-fill'),
    paths: ['categories#product', 'categories#contactUs'],
  },
];

const usersConfig = [
  {
    title: 'admins',
    path: '/dashboard/account',
    icon: getIcon('ic:baseline-supervisor-account'),
    paths: ['accounts#account'],
  },
];

const eventsConfig = [
  {
    title: 'events',
    path: '/dashboard/event',
    icon: getIcon('carbon:events-alt'),
    paths: ['events'],
    children: [
      {
        title: 'questions',
        path: '/dashboard/event/questions',
        icon: 'mdi:question-box-multiple',
        paths: ['events#questions'],
      },
      {
        title: 'question papers',
        path: '/dashboard/event/questionpapers',
        icon: 'mdi:file-question-outline',
        paths: ['events#questionPapers'],
      },
      {
        title: 'job expo',
        path: '/dashboard/event/jobexpo',
        icon: 'grommet-icons:user-manager',
        paths: ['events#jobExpo'],
      },
      {
        title: 'candidates',
        path: '/dashboard/event/candidates',
        icon: 'mdi:account-group-outline',
        paths: ['events#candidates'],
      },
      {
        title: 'answers',
        path: '/dashboard/event/answers',
        icon: 'mdi:clipboard-tick-outline',
        paths: ['events#answers'],
      },
    ],
  },
];

export { dashboardConfig, settingsConfig, mailConfig, hiringConfig, CmsConfig, usersConfig, eventsConfig, crmConfig };
