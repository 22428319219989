import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import LogoSmall from '../../components/LogoSmall';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import {
  eventsConfig,
  dashboardConfig,
  settingsConfig,
  mailConfig,
  hiringConfig,
  CmsConfig,
  usersConfig,
  crmConfig,
} from './NavConfig';
import StateContext from '../../components/Context/Context';
import hasAccessOf from '../../store';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const filterConfig = (config) => {
  const filteredNavConfig = config.filter(
    (item) =>
      item.paths.some((path) => hasAccessOf(path)) &&
      (item.children && item.children.length
        ? item.children.some((child) => child.paths.some((path) => hasAccessOf(path)))
        : true)
  );
  return filteredNavConfig;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { dispatch, state } = useContext(StateContext);
  const trueValue = true;
  const isDesktop = useResponsive('up', 'lg');
  const accountData = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box
        sx={{
          py: 2,
          mb: 4,
          px: 1,
          position: 'relative',
          bgcolor: (theme) => theme.palette.background.paper,
          display: 'flex',
          justifyContent: 'center',
          transition: 'all .3s ease-in-out',
          alignItems: 'center',
          margin: 0,
        }}
      >
        {state ? <Logo /> : <LogoSmall sx={{ height: '50px' }} />}
      </Box>

      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.sidebar' }}>
                {accountData.fullname}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {accountData?.accType?.replace('_', ' ')}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}

      {filterConfig(dashboardConfig).length !== 0 && <NavSection navConfig={filterConfig(dashboardConfig)} />}
      {filterConfig(crmConfig).length !== 0 && <NavSection navConfig={filterConfig(crmConfig)} />}

      {filterConfig(hiringConfig).length !== 0 && <NavSection navConfig={filterConfig(hiringConfig)} />}
      {filterConfig(mailConfig).length !== 0 && <NavSection navConfig={filterConfig(mailConfig)} />}
      {filterConfig(CmsConfig).length !== 0 && <NavSection navConfig={filterConfig(CmsConfig)} />}
      {filterConfig(eventsConfig).length !== 0 && <NavSection navConfig={filterConfig(eventsConfig)} />}
      {filterConfig(usersConfig).length !== 0 && <NavSection navConfig={filterConfig(usersConfig)} />}
      {filterConfig(settingsConfig).length !== 0 && (
        <NavSection navConfig={filterConfig(settingsConfig)} last={trueValue} />
      )}

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />
 
          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: { lg: !state ? 80 : 240 },
        transition: 'all .3s ease-in-out',
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: state ? 240 : 80,
              transition: 'all .3s ease-in-out',
              bgcolor: (theme) => theme.palette.sidebar.dark,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: state ? 240 : 80,
              bgcolor: (theme) => theme.palette.sidebar.dark,
              // borderRightStyle: 'dashed',
              transition: 'all .3s ease-in-out',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
