import axios from 'axios';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Icon,
  Container,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';

// components
import { useEffect, useState } from 'react';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/AnswerFilter';
import { CustomButton } from '../theme/CustomButton';
import Popup from '../components/popups/AnswerStatusChange';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Answers() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 0;
  const size = searchParams.get('size') ? Number(searchParams.get('size')) : 10;
  const [answerData, setAnswerData] = useState();
  const [answerCount, setAnswerCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState({ size, page });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState({
    title: '',
    startDate: '',
    endDate: '',
    isBlocked: '',
    _id: '',
    description: '',
    questionPapers: [],
  });

  const getAnswers = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/answer/all`, {
        params: options,
      });
      console.log('answers', res);
      setAnswerData(res.data.data.records);
      setAnswerCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateAnswer = async () => {
    try {
      setEditSubmitting(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/events/answer/`, {
        title: selectedAnswer.title,
        startDate: selectedAnswer.startDate,
        endDate: selectedAnswer.endDate,
        isBlocked: selectedAnswer.isBlocked,
        id: selectedAnswer.id,
      });
      getAnswers();
      toast(res.data.message);
      setSelectedAnswer({
        title: '',
        startDate: '',
        endDate: '',
        isBlocked: '',
      });
      setOpen1(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleEditAnswer = (e, item) => {
    setSelectedAnswer({
      title: item.title,
      startDate: item.startDate,
      endDate: item.endDate,
      isBlocked: item.isBlocked,
      _id: item._id,
      description: item.description,
      questionPapers: item.questionPapers,
    });
    setOpen1(true);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setOptions(temp);
    setIsLoading(true);
    getAnswers(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(answerCount / options.size)) {
      return;
    }
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    setIsLoading(true);
    getAnswers(temp);
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${!item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getAnswers(options);
    }
    if (message) {
      toast(message);
    }
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size: options.size };
    if (filter.candidateId.length) {
      temp.candidateId = filter.candidateId;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (['true', 'false'].includes(filter.isBlocked)) {
      temp.isBlocked = filter.isBlocked;
    }
    if (['true', 'false'].includes(filter.isFinished)) {
      temp.isFinished = filter.isFinished;
    }
    if (filter.securedMarks) {
      temp.securedMarks = filter.securedMarks;
    }
    if (filter.questionPaperId) {
      temp.questionPaperId = filter.questionPaperId;
    }
    if (filter.jobExpoId) {
      temp.jobExpoId = filter.jobExpoId;
    }
    setOptions(temp);
    getAnswers(temp);
  };

  useEffect(() => {
    if (options.page !== page) {
      if (options.size !== size) {
        setOptions({ ...options, page, size });
        getAnswers({ ...options, page, size });
      } else {
        setOptions({ ...options, page });
        getAnswers({ ...options, page });
      }
    } else {
      getAnswers(options);
    }
  }, [page, size]);

  useEffect(() => {
    if (options.page !== page) {
      setSearchParams({ page: options.page, size: options.size });
    }
    if (options.size !== size) {
      setSearchParams({ size: options.size, page: options.page });
    }
  }, [options.page, options.size]);

  return (
    <Page title="Answers">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Typography variant="h4" mb={4}>
            Answers
          </Typography>
          <Card>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} padding={2}>
                <TextField
                  size="small"
                  style={{ maxWidth: '100px', maxHeight: '35px' }}
                  type="number"
                  component="div"
                  onKeyDown={(e) => e.key === 'Enter' && goToPage()}
                  label="Go to"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                  Go
                </CustomButton>
              </Stack>
              <Stack padding={2} direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                <Filter applyFilters={applyFilters} />
              </Stack>
            </Stack>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Answer ID</TableCell>
                      <TableCell>Job Expo</TableCell>
                      <TableCell>Candidate</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Question Paper</TableCell>
                      <TableCell>Completion Status </TableCell>
                      <TableCell>Marks</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {answerData?.map((item, id) => (
                      <TableRow key={id}>
                        <TableCell>{options.page * options.size + (id + 1)}</TableCell>
                        <TableCell sx={{ width: '130px' }}>{moment(item.createdAt).format('DD-MM-YYYY')}</TableCell>
                        <TableCell sx={{ cursor: 'pointer' }}>
                          <CopyToClipboard onCopy={() => toast('Answer ID copied')} text={item._id}>
                            <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                              <Iconify
                                icon="lucide:clipboard-copy"
                                style={{ height: '18px', width: '18px', color: '#192B6B' }}
                              />
                              <Typography sx={{ color: '#192B6B' }} variant="p">
                                Copy ID
                              </Typography>
                            </Stack>
                          </CopyToClipboard>
                        </TableCell>
                        <TableCell>{item.jobExpo.title}</TableCell>
                        <TableCell>{item.candidate.fullname}</TableCell>
                        <TableCell>{item.candidate.email}</TableCell>
                        <TableCell>{item.questionPaper.title}</TableCell>
                        <TableCell>{item.isFinished ? 'Finished' : 'Not Finished'}</TableCell>
                        <TableCell>{item.securedMarks}</TableCell>
                        <TableCell sx={{ width: '130px' }}>
                          <FormControl sx={{ minWidth: 100 }}>
                            <Select
                              size="small"
                              value={!item.isBlocked}
                              onChange={(e) => handleStatusChange(e, item, id)}
                            >
                              <MenuItem value="true">Active</MenuItem>
                              <MenuItem value="false">Blocked</MenuItem>
                            </Select>
                            {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Icon
                            sx={{ color: 'gray', cursor: 'pointer' }}
                            onClick={() => window.open(`/dashboard/event/answers/view/${item._id}`, '_blank')}
                          >
                            <Iconify icon="subway:eye" />
                          </Icon>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              onRowsPerPageChange={(e) => {
                setOptions({ ...options, size: Number(e.target.value) });
                getAnswers({ ...options, size: Number(e.target.value) });
              }}
              component={'div'}
              showFirstButton
              showLastButton
              count={answerCount}
              onPageChange={handlePageChange}
              rowsPerPage={options.size}
              page={options.page}
            />
          </Card>
        </Container>
      )}
    </Page>
  );
}
