import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
//
import Iconify from './Iconify';
import hasAccessOf from '../store';
import StateContext from './Context/Context';

// ----------------------------------------------------------------------

const BoxItemStyle = styled((props) => <Box component="div" {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 38,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.sidebar.contrastText,
  borderRadius: '19px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textDecoration: 'none',
  backgroundColor: theme.palette.sidebar.darker,
  paddingX: 2,
  cursor: 'pointer',
}));

const BoxItemStyleChildren = styled((props) => <Box component="div" {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 38,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.sidebar.contrastText,
  borderRadius: '19px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textDecoration: 'none',
  paddingX: 2,
  cursor: 'pointer',
}));

const BoxIconStyle = styled((props) => <Box component="div" {...props} />)({
  color: 'sidebar.contrastText',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '50%',
  justifyContent: 'center',
  padding: '3px',
});

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  fontSize: '16px',
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();

  const { dispatch, state } = useContext(StateContext);

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'sidebar.contrastText',
    fontWeight: 'fontWeightMedium',
    // bgcolor: alpha(theme.palette.sidebar.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'sidebar.contrastText',
    fontWeight: 'fontWeightMedium',
    // bgcolor: alpha(theme.palette.sidebar.main, theme.palette.action.selectedOpacity),
  };

  if (children) {
    return (
      <>
        <Tooltip title={!state ? title : ''} placement="right" arrow>
          <BoxItemStyle
            onClick={handleOpen}
            sx={{
              ...(isActiveRoot && activeRootStyle),
              justifyContent: !state ? 'center' : 'space-between',
              paddingX: !state ? 0 : 2,
            }}
          >
            {' '}
            <Stack direction="row" spacing={2}>
              <BoxIconStyle
                sx={{
                  ...(isActiveRoot &&
                    !open && {
                      color: (theme) => theme.palette.sidebar.main,
                      bgcolor: (theme) => theme.palette.sidebar.contrastText,
                    }),
                  ...(!isActiveRoot && {
                    color: (theme) => theme.palette.sidebar.contrastText,
                    bgcolor: 'transparent',
                  }),
                }}
              >
                {icon && icon}
              </BoxIconStyle>
              {state && <Typography variant="body2">{title}</Typography>}
            </Stack>
            {info && info}
            {state && (
              <Iconify
                icon={!open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-upward-fill'}
                sx={{ width: 16, height: 16, mr: 1 }}
              />
            )}
          </BoxItemStyle>
        </Tooltip>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack
            spacing={1.5}
            sx={{
              pl: state ? 2 : 0,
            }}
          >
            {children
              .filter((item) => item.paths.some((path) => hasAccessOf(path)))
              .map((item) => {
                const { title, path, icon } = item;
                const isActiveSub = active(path);

                return (
                  <Tooltip key={title} title={!state ? title : ''} placement="right" arrow>
                    <BoxItemStyleChildren
                      sx={{
                        ...(isActiveSub && activeSubStyle),
                        justifyContent: !state ? 'center' : 'space-between',
                        paddingX: !state ? 0 : 2,
                      }}
                      component={RouterLink}
                      to={path}
                    >
                      <Stack direction="row" spacing={2}>
                        <BoxIconStyle
                          sx={{
                            ...(isActiveSub && {
                              color: (theme) => theme.palette.sidebar.main,
                              bgcolor: (theme) => theme.palette.sidebar.contrastText,
                            }),
                            ...(!isActiveSub && {
                              color: (theme) => theme.palette.sidebar.contrastText,
                              bgcolor: 'transparent',
                            }),
                          }}
                        >
                          <Iconify
                            icon={icon}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: 'inherit',
                              width: 22,
                              height: 22,
                              transition: (theme) => theme.transitions.create('transform'),
                              ...(isActiveSub && {
                                color: (theme) => theme.palette.sidebar.main,
                              }),
                            }}
                          />
                        </BoxIconStyle>
                        {state && <Typography variant="body2">{title}</Typography>}
                      </Stack>
                    </BoxItemStyleChildren>
                  </Tooltip>
                );
              })}
          </Stack>
        </Collapse>
      </>
    );
  }

  return (
    <Tooltip title={!state ? title : ''} placement="right" arrow>
      <BoxItemStyle
        sx={{
          ...(isActiveRoot && activeRootStyle),
          justifyContent: !state ? 'center' : 'space-between',
          paddingX: !state ? 0 : 2,
        }}
        component={RouterLink}
        to={path}
      >
        <Stack direction="row" spacing={2} alignItems="center" justifyContent={state ? 'center' : 'space-between'}>
          <BoxIconStyle
            sx={{
              ...(isActiveRoot && {
                color: (theme) => theme.palette.sidebar.main,
                bgcolor: (theme) => theme.palette.sidebar.contrastText,
              }),
              ...(!isActiveRoot && {
                color: (theme) => theme.palette.sidebar.contrastText,
                bgcolor: 'transparent',
              }),
            }}
          >
            {icon && icon}
          </BoxIconStyle>
          {state && <Typography variant="body2">{title}</Typography>}
        </Stack>
        {info && info}
      </BoxItemStyle>
    </Tooltip>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
  last: PropTypes.bool,
};

export default function NavSection({ navConfig, last = false, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);


  return (
    <Box
      {...other}
      sx={
        {
          // paddingBottom: '300px',
        }
      }
    >
      <Stack spacing={1.5} sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} last={last} />
        ))}
        {!last && <hr />}
      </Stack>
    </Box>
  );
}
