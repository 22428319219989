import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import {
  Stack,
  Button,
  CircularProgress,
  Typography,
  MenuItem,
  TextField,
  Grid,
  Autocomplete,
  Box,
  Avatar,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import Iconify from './Iconify';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.primary.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={profilePic ? 0 : 1}
      >
        {profilePic && (
          <Avatar src={profilePic} alt={title} style={{ height: '100px', width: '100px' }} color="primary" />
        )}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

export default function LeadEditForm({ lead, getLead, setEditMode, editMode }) {
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [status, setStatus] = useState([]);
  const [otherSource, setOtherSource] = useState(false);

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [salesManagers, setSalesManagers] = useState([]);
  const [salesManagersLoading, setSalesManagersLoading] = useState(true);
  const [salesStaffs, setSalesStaffs] = useState([]);
  const [salesStaffsLoading, setSalesStaffsLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      id: lead?._id,
      fullname: lead?.fullname,
      title: lead?.title,
      email: lead?.email,
      phone: lead?.phone,
      address: lead?.address,
      source: lead?.source?._id,
      otherSource: lead?.otherSource,
      category: lead?.category?.map((item) => item._id),
      status: lead?.status?._id,
      salesManager: '',
      salesStaff: '',
      createdAt: Moment(lead?.createdAt).format('YYYY-MM-DD'),
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/lead`, values);
        if (!res.data.isError) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setEditMode(false);
        getLead();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  const getSalesManager = async () => {
    try {
      setSalesManagersLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/manager/list`);
      console.log('lead managers list', res.data.data.records);
      if (!res.data.isError) {
        if (res.data.data.records) {
          setSalesManagers(res.data.data.records);
        }
      }
      setSalesManagersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSalesStaffs = async (managerId) => {
    try {
      setSalesStaffsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/manager/salesStaffs/all`, {
        params: {
          managerId,
        },
      });
      console.log('salesStaffs list', res.data.data);
      if (!res.data.isError) {
        if (res.data.data.salesStaffs) {
          setSalesStaffs(res.data.data.salesStaffs);
        }
      }
      setSalesStaffsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSources = async () => {
    try {
      setSourcesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadSource/all/active`);
      console.log('sources list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setSources(res.data.data.status);
        }
      }
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      setCategoriesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadCategory/all/active`);
      console.log('categories list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setCategories(res.data.data.status);
        }
      }
      setCategoriesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadStatus/all/active`);
      console.log('status list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setStatus(res.data.data.status);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getStatusColor = (statusName) => {
    const statusMapping = {
      Pending: '#fff100',
      Ongoing: '#fff100',
      'Decision Pending': '#fff100',
      Interested: '#16c60c',
      'Not Interested': '#e81224',
    };
    return statusMapping[statusName] || 'white';
  };

  useEffect(() => {
    getSources();
    getCategories();
    getStatus();
    getSalesManager();
  }, []);

  useEffect(() => {
    if (values?.source) {
      const source = sources.find((item) => item._id === values.source);
      if (source?.name === 'Other') {
        setOtherSource(true);
      } else {
        setOtherSource(false);
      }
    }
  }, [values.source, sources]);

  useEffect(() => {
    if (values?.salesManager) {
      getSalesStaffs(values.salesManager);
    }
  }, [values.salesManager]);

  const commaSeperateCategories = (categories) => {
    let categoryString = '';
    categories.forEach((category, index) => {
      categoryString += category.name;
      if (index < categories.length - 1) {
        categoryString += ', ';
      }
    });
    return categoryString;
  };

  if (editMode) {
    return (
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Fullname"
                {...getFieldProps('fullname')}
                error={Boolean(touched.fullname && errors.fullname)}
                helperText={touched.fullname && errors.fullname}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Title"
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Date"
                type="date"
                {...getFieldProps('createdAt')}
                error={Boolean(touched.createdAt && errors.createdAt)}
                helperText={touched.createdAt && errors.createdAt}
              />
            </Grid>

            <Grid item xs={12} md={6}> 
              <TextField
                fullWidth
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                {...getFieldProps('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Status"
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
              >
                {status.map((item, key) => (
                  <MenuItem key={key} value={item._id}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          backgroundColor: getStatusColor(item.name),
                          borderRadius: '50%',
                        }}
                      />
                      <Box>{item.name}</Box>
                    </Stack>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label="Address"
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="asynchronous-demo"
                fullWidth
                open={open3}
                onOpen={() => {
                  setOpen3(true);
                }}
                onClose={() => {
                  setOpen3(false);
                }}
                defaultValue={lead?.source ? lead.source : null}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={sources}
                loading={sourcesLoading}
                onChange={(_, value) => {
                  if (value) {
                    formik.setFieldValue('source', value._id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={Boolean(touched.source && errors.source)}
                    // helperText={touched.source && errors.source}
                    label="Source"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {sourcesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {otherSource && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Other Source"
                  {...getFieldProps('otherSource')}
                  error={Boolean(touched.otherSource && errors.otherSource)}
                  helperText={touched.otherSource && errors.otherSource}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="asynchronous-demo"
                fullWidth
                multiple
                open={open4}
                onOpen={() => {
                  setOpen4(true);
                }}
                onClose={() => {
                  setOpen4(false);
                }}
                defaultValue={lead?.category ? lead.category : null}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={categories}
                loading={categoriesLoading}
                onChange={(_, value) => {
                  if (value) {
                    formik.setFieldValue(
                      'category',
                      value.map((item) => item._id)
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={Boolean(touched.category && errors.category)}
                    // helperText={touched.category && errors.category}
                    label="Category"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {categoriesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="asynchronous-demo"
                fullWidth
                open={open1}
                onOpen={() => {
                  setOpen1(true);
                }}
                onClose={() => {
                  setOpen1(false);
                }}
                defaultValue={lead.salesManager ? lead.salesManager : null}
                isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                getOptionLabel={(option) => option.fullname}
                options={salesManagers}
                loading={salesManagersLoading}
                onChange={(_, value) => {
                  if (value) {
                    setSalesStaffs([]);
                    // setSalesStaffsLoading(true);
                    formik.setFieldValue('salesStaff', null);
                    formik.setFieldValue('salesManager', value._id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sales Manager"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {salesManagersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="asynchronous-demo"
                fullWidth
                open={open2}
                onOpen={() => {
                  setOpen2(true);
                }}
                onClose={() => {
                  setOpen2(false);
                }}
                isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                getOptionLabel={(option) => option.fullname}
                options={salesStaffs}
                loading={salesStaffsLoading}
                onChange={(_, value) => {
                  if (value) {
                    formik.setFieldValue('salesStaff', value._id);
                  } else {
                    formik.setFieldValue('salesStaff', '');
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.salesStaff && errors.salesStaff)}
                    helperText={touched.salesStaff && errors.salesStaff}
                    label="Sales Staff"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {salesStaffsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Stack direction={'row'} justifyContent="flex-end" spacing={1} paddingTop="20px">
            <Button variant="contained" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Update
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={4}>
        <DetailItem
          title="Fullname"
          data={lead?.fullname}
          icon={'mdi:account'}
          // profilePic={process.env.REACT_APP_API_URL + lead?.profilePic}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DetailItem title="Title" data={lead?.title} icon={'pajamas:title'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DetailItem title="Email" data={lead?.email} icon={'mdi:email'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DetailItem title="Phone" data={lead?.phone} icon={'mdi:phone'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {/*  date */}
        <DetailItem title="Date" data={Moment(lead?.createdAt).format('DD-MM-YYYY')} icon={'mdi:calendar'} />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <DetailItem title="Source" data={lead?.source?.name} icon={'fluent-mdl2:source'} />
      </Grid>
      {lead?.otherSource && (
        <Grid item xs={12} md={6} lg={4}>
          <DetailItem title="Other Source" data={lead?.otherSource} icon={'fluent-mdl2:source'} />
        </Grid>
      )}

      <Grid item xs={12} md={6} lg={4}>
        <DetailItem
          title="Category"
          data={lead?.category && lead?.category.length > 0 ? commaSeperateCategories(lead?.category) : 'N/A'}
          icon={'iconamoon:category-fill'}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DetailItem title="Sales Manager" data={lead?.salesManager?.fullname} icon={'iconamoon:category-fill'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DetailItem title="Sales Staff" data={lead?.salesStaff?.fullname} icon={'mdi:phone'} />
      </Grid>
      <Grid item xs={12}>
        <DetailItem title="Address" data={lead?.address} icon={'mdi:map-marker'} />
      </Grid>
    </Grid>
  );
}
