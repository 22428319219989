import axios from 'axios';
import moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Container,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';

// components
import { useEffect, useState } from 'react';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/JobExpoFilter';
import Popup from '../components/popups/JobExpoStatusChange';
import { CustomButton } from '../theme/CustomButton';
import AddJobExpo from '../components/popups/AddJobExpo';
import EditJobExpo from '../components/popups/EditJobExpo';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function JobExpo() {
  const [jobExpoData, setJobExpoData] = useState();
  const [pages, setPages] = useState(0);
  const [jobExpoCount, setJobExpoCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedJobExpo, setSelectedJobExpo] = useState({
    title: '',
    startDate: '',
    endDate: '',
    isBlocked: '',
    _id: '',
    description: '',
    questionPapers: [],
  });

  const getJobExpos = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/jobExpo/all`, {
        params: options,
      });
      console.log('jobExpos', res);
      setJobExpoData(res.data.data.records);
      setJobExpoCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateJobExpo = async () => {
    try {
      setEditSubmitting(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/events/jobExpo/`, {
        title: selectedJobExpo.title,
        startDate: selectedJobExpo.startDate,
        endDate: selectedJobExpo.endDate,
        isBlocked: selectedJobExpo.isBlocked,
        id: selectedJobExpo.id,
      });
      getJobExpos();
      toast(res.data.message);
      setSelectedJobExpo({
        title: '',
        startDate: '',
        endDate: '',
        isBlocked: '',
      });
      setOpen1(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleEditJobExpo = (e, item) => {
    setSelectedJobExpo({
      title: item.title,
      startDate: item.startDate,
      endDate: item.endDate,
      isBlocked: item.isBlocked,
      _id: item._id,
      description: item.description,
      questionPapers: item.questionPapers,
    });
    setOpen1(true);
  };

  const closeEditJobExpoModal = () => {
    setOpen1(false);
    toast();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getJobExpos(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(jobExpoCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getJobExpos(temp);
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${!item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getJobExpos(options);
    }
    if (message) {
      toast(message);
    }
  };
  const handleCloseAddJobExpo = (status, message = '') => {
    if (status) {
      getJobExpos(options);
      toast(message);
    } else {
      toast.error(message);
    }
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.title.length) {
      temp.title = filter.title;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (['true', 'false'].includes(filter.isBlocked)) {
      temp.isBlocked = filter.isBlocked;
    }
    setOptions(temp);
    setPages(0);
    getJobExpos(temp);
  };

  const callApi = () => {
    getJobExpos(options);
  };

  useEffect(() => {
    getJobExpos(options);
  }, []);

  return (
    <Page title="JobExpos">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <>
            {/* Add JobExpo Dialog */}
            <AddJobExpo open={open} setOpen={setOpen} handleClose={handleCloseAddJobExpo} callApi={callApi} />
            {/* Add JobExpo dialog end */}
            {/* edit JobExpo Dialog */}
            {open1 && (
              <EditJobExpo
                open={open1}
                setOpen={setOpen1}
                handleClose={closeEditJobExpoModal}
                callApi={callApi}
                jobExpo={selectedJobExpo}
              />
            )}
            {/* edit JobExpo dialog end */}
            <Container maxWidth="xl">
              <Typography variant="h4" mb={4}>
                Job Expos
              </Typography>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </CustomButton>
                  </Stack>
                  <Stack padding={2} direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                    <CustomButton
                      onClick={() => setOpen(true)}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      variant="contained"
                    >
                      Add Job Expo
                    </CustomButton>
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Job Expo ID</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobExpoData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell sx={{ width: '110px' }}>{id + 1}</TableCell>
                            <TableCell style={{ cursor: 'pointer' }} sx={{ width: '130px' }}>
                              <Stack direction="column" spacing={0.5}>
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('Job Expo ID copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '18px', width: '18px', color: '#192B6B' }}
                                    />
                                    <Typography sx={{ color: '#192B6B' }} variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell sx={{ width: '130px' }}>{moment(item.startDate).format('DD-MM-YYYY')}</TableCell>
                            <TableCell sx={{ width: '130px' }}>{moment(item.endDate).format('DD-MM-YYYY')}</TableCell>
                            <TableCell sx={{ width: '130px' }}>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={!item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="true">Active</MenuItem>
                                  <MenuItem value="false">Blocked</MenuItem>
                                </Select>
                                {selectedIndex === id && (
                                  <Popup item={item} path="/jobExpo/status" handleClose={handleClose} />
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell style={{ width: '100px' }}>
                              <Iconify
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) => handleEditJobExpo(e, item)}
                                icon="clarity:edit-solid"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  showFirstButton
                  showLastButton
                  count={jobExpoCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        </>
      )}
    </Page>
  );
}
