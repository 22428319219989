import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function EditJobExpo({ open, setOpen, handleClose, callApi, jobExpo }) {
  const [questionPapers, setQuestionPapers] = React.useState([]);

  const getQuestionPapers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/questionPaper/finalized`);
      console.log(res);
      setQuestionPapers(res.data.data.records);
    } catch (error) {
      console.log(error);
    }
  };

  const jobExpoSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    startDate: Yup.string().required('Start Date is required'),
    endDate: Yup.string().required('End Date is required'),
    questionPapers: Yup.array().required('Question Papers are required'),
    description: Yup.string().required('Description is required'),
  });

  const formik = useFormik({
    initialValues: {
      id: jobExpo._id,
      title: jobExpo.title,
      startDate: jobExpo.startDate,
      endDate: jobExpo.endDate,
      isBlocked: jobExpo.isBlocked,
      questionPapers: jobExpo.questionPapers,
      description: jobExpo.description,
    },
    validationSchema: jobExpoSchema,
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/events/jobExpo`, values);
        toast(res.data.message);
        callApi();
        setOpen(false);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  React.useEffect(() => {
    getQuestionPapers();
  }, []);

  return (
    <Dialog open={open}>
      <DialogTitle>Edit Job Expo</DialogTitle>
      <DialogContent paddingTop="20px">
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={1} width="500px" marginY={2}>
              <Stack direction="row" justifyContent="space-between">
                <TextField
                  type="text"
                  label="JobExpo"
                  fullWidth
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Stack>
              <Autocomplete
                multiple
                id="questionPapers"
                options={questionPapers}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option._id === value._id}
                value={
                  values.questionPapers
                    ? values.questionPapers.map((id) => questionPapers.find((qp) => qp._id === id)).filter(Boolean)
                    : []
                }
                onChange={(_, value) => {
                  formik.setFieldValue(
                    'questionPapers',
                    value.map((qp) => qp._id)
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Question Papers"
                    error={Boolean(touched.questionPapers && errors.questionPapers)}
                    helperText={touched.questionPapers && errors.questionPapers}
                  />
                )}
              />

              <TextField
                type="text"
                label="Description"
                fullWidth
                multiline
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />

              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <TextField
                  type="date"
                  label="Start Date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...getFieldProps('startDate')}
                  error={Boolean(touched.startDate && errors.startDate)}
                  helperText={touched.startDate && errors.startDate}
                />
                <TextField
                  type="date"
                  label="End Date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...getFieldProps('endDate')}
                  error={Boolean(touched.endDate && errors.endDate)}
                  helperText={touched.endDate && errors.endDate}
                />
              </Stack>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <LoadingButton variant="contained" onClick={handleSubmit} loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
