import axios from 'axios';
/* eslint-disable react/prop-types */
import { BorderColor, Close, Edit, RemoveRedEye } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from './Iconify';
import Popup from './popups/EmployeeStatusChange';
import EmployeeEditForm from './EmployeeEditForm';

function DetailItem({ icon, title, data, background, sx, onClick }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.primary.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={1}
      >
        <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

export default function EmployeeBasicInfo({ employee, getEmployee }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [popup, setPopup] = useState(false);
  const [paymentData, setPaymentData] = useState({});

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    setPopup(true);
  };

  const handleClose = (refresh = false, message = '') => {
    setPopup(false);
    if (refresh) {
      getEmployee();
    }
    if (message) {
      toast(message);
    }
  };

  const getIcon = (role) => {
    switch (role) {
      case 'SALES_STAFF':
        return 'mdi:phone-outline';
      case 'SALES_MANAGER':
        return 'mdi:account-tie';
      case 'PROJECT_MANAGER':
        return 'mdi:account-tie';
      case 'PROJECT_ENGINEER':
        return 'mdi:account-tie';
      case 'WORKER':
        return 'mdi:worker';
      case 'ACCOUNT_MANAGER':
        return 'carbon:account';
      case 'WAREHOUSE_MANAGER':
        return 'maki:warehouse';
      default:
        return 'mdi:account';
    }
  };

  const setChipColor = (role) => {
    switch (role) {
      case 'SALES_STAFF':
        return 'navy';
      case 'SALES_MANAGER':
        return 'greenish';
      default:
        return 'purplish';
    }
  };

  // const getPaymentData = async () => {
  //   try {
  //     const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/salary/all`, {
  //       params: { employeeId: employee?._id },
  //     });
  //     setIsLoading(false);
  //     setPaymentData(res.data.data.records);
  //     console.log('res', res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (employee?._id) {
      // getPaymentData();
      setIsLoading(!employee.fullname);
    }
  }, [employee]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box
            sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
            paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
            paddingTop={1}
            paddingBottom={5}
          >
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="end" mb={2}>
              <FormControl sx={{ minWidth: 100 }}>
                <Select size="small" value={employee?.isBlocked} onChange={(e) => handleStatusChange(e, employee)}>
                  <MenuItem value={'false'}>🟢 Active</MenuItem>
                  <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                </Select>
                {popup && <Popup item={employee} handleClose={handleClose} />}
              </FormControl>
              {!editMode && (
                <IconButton onClick={() => setEditMode(!editMode)}>
                  <BorderColor />
                </IconButton>
              )}
              {editMode && (
                <IconButton onClick={() => setEditMode(!editMode)}>
                  <Close />
                </IconButton>
              )}
            </Stack>
            <Box maxWidth="lg">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    sx={{
                      height: '100%',
                      width: '100%',
                    }}
                    spacing={2}
                    mt={2}
                  >
                    <Box
                      component="img"
                      src={
                        (employee?.profilePic && process.env.REACT_APP_API_URL + employee.profilePic) ||
                        'https://www.w3schools.com/howto/img_avatar.png'
                      }
                      sx={{ width: '150px', borderRadius: '50%', height: '150px', objectFit: 'cover' }}
                    />
                    <Chip
                      label={employee?.role !== 'SALES_STAFF' ? employee?.role?.replaceAll('_', ' ') : 'SALES STAFF'}
                      icon={<Iconify sx={{ width: '25px', height: '25px' }} icon={getIcon(employee.role)} />}
                      color={setChipColor(employee.role)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={8}>
                  {!editMode && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        alignItems: 'center',
                        maxWidth: '600px',
                        margin: 'auto',
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <DetailItem title="Full Name" data={employee?.fullname} icon={'mdi:user-circle'} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <DetailItem title="Email" data={employee?.email} icon={'mdi:email'} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <CopyToClipboard text={employee?._id} onCopy={() => toast.success('Employee ID Copied')}>
                            <DetailItem
                              title="Employee ID"
                              data={employee?._id}
                              icon={'mdi:account-badge-horizontal'}
                              sx={{ cursor: 'pointer' }}
                            />
                          </CopyToClipboard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <DetailItem
                            title="Member Since"
                            data={moment(employee?.createdAt).format('DD MMM YYYY')}
                            icon={'mdi:calendar'}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {editMode && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        alignItems: 'center',
                        maxWidth: '600px',
                        margin: 'auto',
                      }}
                    >
                      <EmployeeEditForm employee={employee} setEditMode={setEditMode} getEmployee={getEmployee} />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
