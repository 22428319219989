import axios from 'axios';
import moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Container,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';

// components
import { useEffect, useState } from 'react';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/CandidateFilter';
import Popup from '../components/popups/CandidateStatusChange';
import { CustomButton } from '../theme/CustomButton';
import AddCandidate from '../components/popups/AddCandidate';
// import EditCandidate from '../components/popups/EditCandidate';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Candidate() {
  const [candidateData, setCandidateData] = useState();
  const [pages, setPages] = useState(0);
  const [candidateCount, setCandidateCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({
    title: '',
    startDate: '',
    endDate: '',
    isBlocked: '',
    _id: '',
    description: '',
    questionPapers: [],
  });

  const getCandidates = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/candidate/all`, {
        params: options,
      });
      console.log('candidates', res);
      setCandidateData(res.data.data.records);
      setCandidateCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateCandidate = async () => {
    try {
      setEditSubmitting(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/events/candidate/`, {
        title: selectedCandidate.title,
        startDate: selectedCandidate.startDate,
        endDate: selectedCandidate.endDate,
        isBlocked: selectedCandidate.isBlocked,
        id: selectedCandidate.id,
      });
      getCandidates();
      toast(res.data.message);
      setSelectedCandidate({
        title: '',
        startDate: '',
        endDate: '',
        isBlocked: '',
      });
      setOpen1(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleEditCandidate = (e, item) => {
    setSelectedCandidate({
      title: item.title,
      startDate: item.startDate,
      endDate: item.endDate,
      isBlocked: item.isBlocked,
      _id: item._id,
      description: item.description,
      questionPapers: item.questionPapers,
    });
    setOpen1(true);
  };

  const closeEditCandidateModal = () => {
    setOpen1(false);
    toast();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getCandidates(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(candidateCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getCandidates(temp);
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${!item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getCandidates(options);
    }
    if (message) {
      toast(message);
    }
  };
  const handleCloseAddCandidate = (status, message = '') => {
    if (status) {
      getCandidates(options);
      toast(message);
    } else {
      toast.error(message);
    }
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.fullname.length) {
      temp.fullname = filter.fullname;
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.phoneNumber.length) {
      temp.phoneNumber = filter.phoneNumber;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (['true', 'false'].includes(filter.isBlocked)) {
      temp.isBlocked = filter.isBlocked;
    }
    setOptions(temp);
    setPages(0);
    getCandidates(temp);
  };

  const callApi = () => {
    getCandidates(options);
  };

  useEffect(() => {
    getCandidates(options);
  }, []);

  return (
    <Page title="Candidates">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <>
            {/* Add Candidate Dialog */}
            <AddCandidate open={open} setOpen={setOpen} handleClose={handleCloseAddCandidate} callApi={callApi} />
            {/* Add Candidate dialog end */}
            {/* edit Candidate Dialog */}
            {/* {open1 && <EditCandidate open={open1} setOpen={setOpen1} handleClose={closeEditCandidateModal} callApi={callApi} candidate={selectedCandidate} />} */}
            {/* edit Candidate dialog end */}
            <Container maxWidth="xl">
              <Typography variant="h4" mb={4}>
                Candidates
              </Typography>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      onKeyDown={(e) => e.key === 'Enter' && goToPage()}
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </CustomButton>
                  </Stack>
                  <Stack padding={2} direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                    <CustomButton
                      onClick={() => setOpen(true)}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      variant="contained"
                    >
                      Add Candidate
                    </CustomButton>
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Candidate ID</TableCell>
                          <TableCell>Fullname</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {candidateData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell sx={{ width: '110px' }}>{id + 1}</TableCell>
                            <TableCell style={{ cursor: 'pointer' }} sx={{ width: '130px' }}>
                              <Stack direction="column" spacing={0.5}>
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('Candidate ID copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '18px', width: '18px', color: '#192B6B' }}
                                    />
                                    <Typography sx={{ color: '#192B6B' }} variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>
                            <TableCell>{item.fullname}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.phoneNumber}</TableCell>
                            <TableCell sx={{ width: '130px' }}>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={!item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="true">Active</MenuItem>
                                  <MenuItem value="false">Blocked</MenuItem>
                                </Select>
                                {selectedIndex === id && (
                                  <Popup item={item} path="/candidate/status" handleClose={handleClose} />
                                )}
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  showFirstButton
                  showLastButton
                  count={candidateCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        </>
      )}
    </Page>
  );
}
