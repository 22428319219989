import { useState } from 'react';
import { Grid } from '@mui/material';
// material
import TabCard from '../smallComponents/TabCard';

// components

export default function SalesManagerDetailPageTabs({ salesManager }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={2.4}>
          <TabCard
            title="Sales Staffs"
            color="greenish"
            icon={'material-symbols:call'}
            navigate={`/dashboard/crm/salesStaffs?managerId=${salesManager._id}`}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2.4}>
          <TabCard
            title="Leads"
            color="orangee"
            icon={'mdi:leads'}
            navigate={`/dashboard/crm/leads?managerId=${salesManager._id}`}
          />
        </Grid>
      </Grid>
    </>
  );
}
