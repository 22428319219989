import React, { useState } from 'react';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import 'react-vertical-timeline-component/style.min.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { Box, Button, Card, Dialog, DialogContent, DialogTitle, Divider, Grid, Stack } from '@mui/material';
import Iconify from './Iconify';

const Timeline = ({ timeline, handleEditPopup, handleDelete, editState }) => {
  const accName = JSON.parse(localStorage.getItem('profile'));
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const handleDeletePopup = (id) => {
    setOpen(!open);
    setId(id);
  };
  const handleDeleteTimeline = () => {
    handleDelete(id);
    setOpen(false);
  };
  return (
    <>
      <VerticalTimeline>
        {timeline?.map((data, index) => {
          return (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#192B6B', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #192B6B' }}
              date={moment(data.createdAt).format('DD-MM-YYYY')}
              dateClassName="dateTimeline"
              iconStyle={{ background: '#192B6B', color: '#fff' }}
              icon={<Iconify icon="ic:outline-timeline" />}
              key={index}
            >
              <h3 className="vertical-timeline-element-title">{accName.fullname}</h3>
              {editState && (
                <Box sx={{ position: 'absolute', right: 0, top: 0, display: 'flex', gap: '2px' }}>
                  <Iconify
                    icon="ant-design:edit-filled"
                    style={{
                      height: '25px',
                      width: '25px',
                      margin: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleEditPopup(data)}
                  />
                  <Iconify
                    icon="ant-design:delete-filled"
                    style={{
                      height: '25px',
                      width: '25px',
                      margin: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleDeletePopup(data._id)}
                  />
                </Box>
              )}

              <h4 className="vertical-timeline-element-subtitle">{data.type}</h4>
              <p style={{ marginBottom: '10px' }}>{data.content}</p>
              {data.files.length ? (
                <Accordion sx={{ background: '#192B6B', color: '#ffff' }} disableGutters>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>
                      <Iconify icon="et:attachments" style={{ marginRight: '10px' }} />
                      Attachments
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {data.files?.map((item, index) => (
                      <Button key={index} onClick={() => window.open(item?.fileURL, '_blank')}>
                        <img src={item?.fileURL} alt="timeline attachments" width="50px" />
                      </Button>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : (
                ''
              )}
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>

      {/* Delete job Dialog */}
      <Dialog open={open}>
        <DialogTitle>Delete Timeline?</DialogTitle>
        <Grid container paddingBottom={'10px'} Width="500px">
          <DialogContent paddingTop="20px">
            <Grid item xs={12} md={12} lg={12}>
              <Stack direction={'column'} spacing={1} width="500px">
                <Stack direction="row" justifyContent="space-between">
                  Are you sure you want to delete this Timeline?
                </Stack>
              </Stack>
            </Grid>
            <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
              <Button onClick={handleDeletePopup}>No</Button>
              <Button type="submit" onClick={handleDeleteTimeline}>
                Yes
              </Button>
            </Stack>
          </DialogContent>
        </Grid>
      </Dialog>
      {/* Delete Timeline dialog end */}
    </>
  );
};

export default Timeline;
