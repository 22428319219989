// ----------------------------------------------------------------------
//
const accountData = JSON.parse(localStorage.getItem('profile'));
const account = {
  displayName: accountData ? accountData.fullname : 'Name',
  email: accountData ? accountData.email : 'Email',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
};

export default account;
