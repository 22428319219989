import { useState } from 'react';
import { Grid } from '@mui/material';
// material
import TabCard from '../smallComponents/TabCard';

// components

export default function SalesStaffDetailPageTabs({ salesStaff, managerId }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={2.4}>
          <TabCard
            title="Sales Manager"
            color="greenish"
            icon={'mdi:account-tie'}
            navigate={`/dashboard/crm/salesManagers/view/${managerId}`}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2.4}>
          <TabCard
            title="Leads"
            color="orangee"
            icon={'mdi:leads'}
            navigate={`/dashboard/crm/leads?salesStaffId=${salesStaff._id}`}
          />
        </Grid>
      </Grid>
    </>
  );
}
