import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  OutlinedInput,
  InputAdornment,
  Chip,
} from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filter/Leads';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/LeadStatusChange';
import Export from '../components/popups/LeadExport';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Leads() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const customerId = searchParams.get('customerId');
  const managerId = searchParams.get('managerId');
  const salesStaffId = searchParams.get('salesStaffId');

  const [selectedFilter, setSelectedFilter] = useState({});

  const [leadData, setLeadData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [leadCount, setLeadCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');

  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({
    size,
    page: 0,
    customer: customerId,
    salesManager: managerId,
    salesStaff: salesStaffId,
  });
  const [selectedStatus, setSelectedStatus] = useState({});

  // for export

  const getStatus = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadStatus/all/active`);
      console.log('status list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setStatus(res.data.data.status);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.statusData.name}` === event.target.value) {
      return;
    }
    const temp = status.filter((statusItem) => statusItem.name === event.target.value)[0];
    console.log(temp);
    setSelectedStatus(temp);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getLeads(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getLeads(temp);
  };

  const getLeads = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/lead/all`, {
        params: options,
      })
      .then((res) => {
        console.log('Leads', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLeadCount(res.data.data.maxRecords);
          setLeadData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err); 
        setIsLoading(false);
      });
  };

  const getLeadsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/lead/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setLeadCount(res.data.data.maxRecords);
          setLeadData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getStatusColor1 = (statusName) => {
    const statusMapping = {
      Pending: '#eaea7e',
      Ongoing: '#FDDA0D',
      'Decision Pending': '#d1bc00',
      Interested: '#0b9703',
      'Not Interested': '#CC5500',
      Regret: '#EE4B2B',
    };
    return statusMapping[statusName] || '';
  };

  const exportLead = async (options) => {
    console.log(options);
    setIsLoading1(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/lead/export`, options);
      console.log('res', res);
      setIsLoading1(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
      toast.success(error.response.data.message);
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, fullname: e.target.value };

    setOptions(temp);
    setPages(0);
    getLeadsWithoutLoading(temp);
  };

  const handleStatusFilterChange = (event) => {
    if (event.target.value !== 'all') {
      const temp = { ...options, status: event.target.value };

      setOptions(temp);
      setIsLoading(true);
      getLeads(temp);
      setStatusFilter(event.target.value);
    } else {
      const temp = { ...options, status: '' };

      setOptions(temp);
      setIsLoading(true);
      getLeads(temp);
      setStatusFilter('all');
    }
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { ...options, page: 0 };
    if (filter.fullname) {
      temp.fullname = filter.fullname;
    }
    if (filter.email) {
      temp.email = filter.email.trim();
    }
    if (filter.phone) {
      temp.phone = filter.phone;
    }
    if (filter.status) {
      temp.status = filter.status;
    }
    if (filter.category) {
      temp.category = filter.category;
    }
    if (filter.source) {
      temp.source = filter.source;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.customer) {
      temp.customer = filter.customer;
    }

    setOptions(temp);
    setPages(0);
    getLeads(temp);
    setSelectedFilter(filter);
  };

  useEffect(() => {
    getLeads(options);
  }, []);

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <>
      <Page title="Leads">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <Typography variant="h4" mb={4}>
                Leads
              </Typography>
              {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="end" alignItems={'center'} spacing={5}>
                  <Stack direction="row" sx={{ width: '100%' }} justifyContent="end" alignItems={'center'} spacing={1}>
                    <Export />
                    <LoadingButton
                      endIcon={<Iconify icon="ph:export-bold" />}
                      variant="outlined"
                      color="primary"
                      onClick={() => exportLead(options)}
                      loading={isLoading1}
                      size="small"
                    >
                      Export
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Stack> */}
              <Filter applyFilters={applyFilters} selectedFilter={selectedFilter} filterValues={options} />
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search lead..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                    <TextField
                      select
                      value={statusFilter}
                      label="Status"
                      onChange={(e) => handleStatusFilterChange(e)}
                      sx={{ width: '150px', height: '100%' }}
                    >
                      <MenuItem value={'all'}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                          <Box
                            sx={{
                              width: 14,
                              height: 14,
                              backgroundColor: '#fff',
                              borderRadius: '50%',
                            }}
                          />
                          <Box>All</Box>
                        </Stack>
                      </MenuItem>
                      {status.map((item, index) => (
                        <MenuItem value={item._id} key={index}>
                          <Stack direction="row" key={index} spacing={0.5} alignItems="center">
                            <Box
                              sx={{
                                width: 14,
                                height: 14,
                                backgroundColor: getStatusColor1(item.name),
                                borderRadius: '50%',
                              }}
                            />
                            <Box>{item.name}</Box>
                          </Stack>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      onClick={() => navigate('/dashboard/crm/leads/add')}
                    >
                      Add Lead
                    </Button>
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>Sl No</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Lead No.</TableCell>
                          <TableCell>Fullname</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Source</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leadData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell>
                              <Box
                                sx={{
                                  width: 14,
                                  height: 34,
                                  backgroundColor: getStatusColor1(item?.statusData?.name),
                                  padding: 0,
                                  margin: 0,
                                }}
                              />
                            </TableCell>
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            <TableCell>{Moment(item.createdAt).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>
                              {item?.categoryData?.map((category, index) => (
                                <React.Fragment key={index}>
                                  <Chip
                                    key={index}
                                    label={category.name?.replaceAll('_', ' ')}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    sx={{ margin: '2px' }}
                                  />
                                </React.Fragment>
                              ))}
                            </TableCell>
                            <TableCell>{item.leadNumber}</TableCell>
                            <TableCell sx={{ minWidth: '150px' }}>{item.fullname}</TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.phone}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.customer ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{item?.sourceData?.name}</TableCell>
                            <TableCell>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item?.statusData?.name}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  {status &&
                                    status?.map((item) => (
                                      <MenuItem key={item._id} value={item.name}>
                                        <Stack direction="row" spacing={0.5} alignItems="center">
                                          <Box
                                            sx={{
                                              width: 14,
                                              height: 14,
                                              backgroundColor: getStatusColor1(item.name),
                                              borderRadius: '50%',
                                            }}
                                          />
                                          <Box>{item.name}</Box>
                                        </Stack>
                                      </MenuItem>
                                    ))}
                                </Select>
                                {selectedIndex === id && (
                                  <Popup item={item} handleClose={handleClose} status={selectedStatus} />
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1}>
                                <Link to={`/dashboard/crm/leads/view/${item._id}`}>
                                  <Icon sx={{ color: 'gray' }}>
                                    <Iconify icon="subway:eye" />
                                  </Icon>
                                </Link>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                    const temp = { ...options, size: e.target.value, page: 0 };
                    setPages(0);
                    setOptions(temp);
                    getLeads(temp);
                  }}
                  component={'div'}
                  count={leadCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
