import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Box,
  IconButton,
} from '@mui/material';
import { Cancel, Close, CloseFullscreenTwoTone, CloseRounded } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { useEffect, useState } from 'react';
import hasAccessOf from '../store';
import { CustomButton } from '../theme/CustomButton';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/TestimonialFilter';
import Popup from '../components/popups/TestimonialStatus';
import AddTestimonial from '../components/AddTestimonial';
import EditTestimonial from '../components/EditTestimonial';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Testimonials() {
  const navigate = useNavigate();
  const [testimonialData, setTestimonialData] = useState();
  const [pages, setPages] = useState(0);
  const [testimonialCount, setTestimonialCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editItem, setEditItem] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [image, setImage] = useState();

  const getTestimonials = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/testimonial/all`, {
        params: options,
      });
      console.log('testimonials', res);
      setTestimonialData(res.data.data.testimonials);
      setTestimonialCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getTestimonials(pages);
    }
    if (message) {
      toast(message);
    }
  };

  const handleOpenAddTestimonial = () => {
    setOpen(true);
  };
  const handleCloseAddTestimonial = (message, error) => {
    setOpen(false);
    if (message && !error) {
      toast(message);
    } else if (message && error) {
      toast.error(message);
    }
    getTestimonials(options);
  };

  const handleOpenEditTestimonial = (e, item) => {
    setEditItem(item);
    setOpenEdit(true);
  };
  const handleCloseEditTestimonial = (message, error) => {
    setOpenEdit(false);
    if (message && !error) {
      toast(message);
    } else if (message && error) {
      toast.error(message);
    }
    getTestimonials(options);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTestimonials(temp);
  };

  const handleDelete = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/testimonial/delete`, {
        id: deleteId,
      });
      console.log(res);
      toast(res.data.message);
      getTestimonials(options);
      setOpenDelete(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  const handleImagePreview = (image) => {
    setOpenImagePreview(true);
    setImage(image);
  };

  const goToPage = () => {
    if (input > Math.ceil(testimonialCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getTestimonials(temp);
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isEnabled}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked;
    }
    if (filter.title.length) {
      temp.title = filter.title;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }

    setOptions(temp);
    setPages(0);
    getTestimonials(temp);
  };

  useEffect(() => {
    getTestimonials(options);
  }, []);

  return (
    hasAccessOf('testimonials#testimonial') && (
      <Page title="Testimonials">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* add testimonial popup */}
            {open && <AddTestimonial handleClose={handleCloseAddTestimonial} />}
            {/* end add testimonial popup  */}

            {/* edit popup */}
            {openEdit && <EditTestimonial handleClose={handleCloseEditTestimonial} item={editItem} />}
            {/* edit popup end  */}

            {/* delete popup */}
            <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
              <DialogTitle>Delete Testimonial</DialogTitle>
              <DialogContent>
                <DialogContentText>Are you sure you want to delete this testimonial?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDelete(false)} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => handleDelete()} color="primary" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            {/* delete popup end  */}

            {/* image preview popup  */}
            <Dialog
              open={openImagePreview}
              onClose={() => setOpenImagePreview(false)}
            >
              <Box
                component="img"
                src={image}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
               
                }}
                onClick={() => setOpenImagePreview(false)}
              >
                <Cancel 
                sx={{
                  fontSize: '30px',
                }} />
              </IconButton>
            </Dialog>

            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between">
               <Typography variant="h4" mb={4}>Testimonials</Typography>
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </CustomButton>
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <CustomButton
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      variant="contained"
                      onClick={handleOpenAddTestimonial}
                    >
                      Add New
                    </CustomButton>
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      {/* <JobPostListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Image</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {testimonialData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell style={{ width: '20px' }}>{pages * size + (id + 1)}</TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  wordBreak: 'break-word',
                                }}
                              >
                                {item.title}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Box
                                component="img"
                                src={item.image}
                                sx={{ width: 60, height: 60, objectFit: 'contain', cursor: 'pointer' }}
                                onClick={() => handleImagePreview(item.image)}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  wordBreak: 'break-word',
                                  maxWidth: '400px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item.description}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ maxWidth: '100px' }}>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="false">ACTIVE</MenuItem>
                                  <MenuItem value="true">BLOCKED</MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCell>
                            <TableCell sx={{ maxWidth: '130px' }}>
                              <Box>
                                <IconButton onClick={(e) => handleOpenEditTestimonial(e, item)}>
                                  <Iconify icon="eva:edit-2-fill" />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => {
                                    setDeleteId(item._id);
                                    setOpenDelete(true);
                                  }}
                                >
                                  <Iconify icon="eva:trash-2-fill" />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  count={testimonialCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    )
  );
}
