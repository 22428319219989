import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  Box,
  Container,
  Button,
  MenuItem,
  Select,
  FormControl,
  Card,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Switch,
  FormControlLabel,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/// file pond //
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { FilePond } from 'react-filepond';
import { LoadingButton } from '@mui/lab';
import Iconify from '../Iconify';
import Timeline from '../Timeline';
import TimelineMui from '../TimelineMui';
import PageHeader from '../smallComponents/PageHeader';

export default function ViewResume() {
  const { id } = useParams();
  const [resumeData, setResumeData] = useState();
  const [timeline, setTimeline] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [editState, setEditState] = useState(false);
  const [content, setContent] = useState('');
  const [selectedTimeline, setSelectedTimeline] = useState({
    id: '',
    content: '',
    files: [],
  });
  const [files, setFiles] = useState();
  const [editfiles, setEditFiles] = useState();

  const handlePopup = (data) => {
    setOpen(!open);
    setContent('');
    setFiles();
  };

  const handleEditPopup = (data) => {
    setOpen2(!open2);
    console.log('data=>', data);
    setSelectedTimeline({ id: data._id, content: data.content, files: data.files });
    setEditFiles();
  };

  const handleCreate = async () => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('resumeId', id);
      formData.append('content', content);
      if (files?.length) {
        files.forEach((item) => {
          formData.append('images', item.file);
        });
      }
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/timeline`, formData);
      if (!res.data.isError) {
        setOpen(!open);
        setContent('');
        setFiles();
        getTimeline();
        setIsSubmitting(false);
      }
      console.log('Response', res);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const handleEdit = async () => {
    setIsSubmitting(true);
    console.log(selectedTimeline);
    console.log(editfiles);
    try {
      const formData = new FormData();
      formData.append('id', selectedTimeline.id);
      formData.append('content', selectedTimeline.content);
      if (editfiles?.length) {
        editfiles.forEach((item) => {
          formData.append('images', item.file);
        });
      }

      const res = await axios.put(`${process.env.REACT_APP_API_URL}/timeline`, formData);
      if (!res.data.isError) {
        setOpen2(!open2);
        getTimeline();
        setEditFiles();
        setIsSubmitting(false);
      }
      console.log('Response', res);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };
  const getResumeData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/resume`, { params: { id } });
      console.log('Response', res);
      setResumeData(res.data.data.resume[0]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getTimeline = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/timeline/all`, { params: { resumeId: id } });
      console.log('Response', res);
      setTimeline(res.data.data.records);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatusChange = async (event, id) => {
    console.log(event.target.value);
    try {
      const res = await axios
        .put(`${process.env.REACT_APP_API_URL}/resume/status`, {
          id,
          status: event.target.value,
        })
        .then((res) => {
          if (!res.data.isError) {
            toast.success(`Status Changed to ${event.target.value}`);
            getResumeData();
          }
        });
      console.log('resumes', res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios
        .post(`${process.env.REACT_APP_API_URL}/timeline/delete`, {
          id,
        })
        .then((res) => {
          if (!res.data.isError) {
            toast.success(`Timeline deleted`);
            getTimeline();
          }
        });
      console.log('time', res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditState = () => {
    setEditState(!editState);
  };

  useEffect(() => {
    getResumeData();
    getTimeline();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Grid>
            <Stack marginBottom={2} direction="row" justifyContent={'space-between'}>
              <Box>
                {' '}
                <PageHeader title="View Resume" />
              </Box>

              <Box sx={{ display: 'flex', gap: '8px' }}>
                {' '}
                <Button
                  variant="outlined"
                  sx={{
                    color: '#6082B6',
                  }}
                  size="small"
                  endIcon={<Iconify icon="subway:eye" />}
                  onClick={() => window.open(resumeData?.file?.fileURL, '_blank')}
                >
                  VIEW
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    color: '#A0522D',
                  }}
                  endIcon={<Iconify icon="akar-icons:download" />}
                  onClick={() => window.open(resumeData?.file?.downloadURL, '_blank')}
                >
                  DOWNLOAD
                </Button>
                {/* <Button variant="outlined" color="secondary" size="small" endIcon={<Iconify icon="carbon:task-view" />}>
                  {resumeData?.status}
                </Button> */}
              </Box>
            </Stack>
          </Grid>
          <Box
            sx={{
              boxShadow: '#6E8AEE 0px 1px 4px',
              borderRadius: '10px',
              padding: 1,
              backgroundColor: '#F1F5F9',
              marginBottom: '30px',
            }}
          >
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      {/* <Link to={`/dashboard/user/${resumeData?._id}`}> */}
                      <Iconify icon="wpf:name" style={{ height: '30px', width: '30px', color: 'white' }} />
                      {/* </Link> */}
                    </Stack>

                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                      <Stack direction="column" sx={{ padding: 1 }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Fullname</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>
                          {resumeData?.fullname?.toUpperCase()}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify icon="ic:baseline-email" style={{ height: '30px', width: '30px', color: 'white' }} />
                    </Stack>
                    <CopyToClipboard text={resumeData?.email} onCopy={() => toast.info('Email ID Copied')}>
                      <Stack direction="column" sx={{ padding: 1, cursor: 'pointer' }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Email</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{resumeData?.email}</Typography>
                      </Stack>
                    </CopyToClipboard>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify icon="ic:baseline-email" style={{ height: '30px', width: '30px', color: 'white' }} />
                    </Stack>
                    <CopyToClipboard text={resumeData?.email} onCopy={() => toast.info('Email ID Copied')}>
                      <Stack direction="column" sx={{ padding: 1, cursor: 'pointer' }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Designation</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{resumeData?.category}</Typography>
                      </Stack>
                    </CopyToClipboard>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Stack sx={{ borderRadius: '5px 0px 0px 5px', border: '1px solid #C4D0FD', background: 'white' }}>
                    <Typography textAlign="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      Resume Status
                    </Typography>
                    <FormControl fullWidth sx={{ p: 1 }}>
                      <Select
                        size="small"
                        value={resumeData?.status ? resumeData.status : 'Select Status'}
                        onChange={(e) => handleStatusChange(e, resumeData._id)}
                      >
                        <MenuItem value="AWAITING_REVIEW">AWAITING REVIEW</MenuItem>
                        <MenuItem value="REVIEWED">REVIEWED</MenuItem>
                        <MenuItem value="CONTACTING">CONTACTING</MenuItem>
                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                        <MenuItem value="HIRED">HIRED</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            {/* <TimelineMui timeline={timeline} /> */}
          </Box>
          <Card
            sx={{
              boxShadow: '#6E8AEE 0px 1px 4px',
              borderRadius: '10px',
              padding: 1,
              backgroundColor: editState ? '#BAE6FD' : '#F1F5F9',
            }}
          >
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
              <CardHeader title="Timeline" subheader="Resume Timelines" />

              <Button
                variant="outlined"
                color="secondary"
                size="small"
                endIcon={<Iconify icon="ant-design:edit-filled" />}
                onClick={handlePopup}
              >
                Add Timeline
              </Button>
              <FormControlLabel control={<Switch onClick={handleEditState} />} label="Edit Mode" />
            </Stack>

            <Timeline
              timeline={timeline}
              handleEditPopup={handleEditPopup}
              handleDelete={handleDelete}
              editState={editState}
            />
          </Card>

          {/* dialog popup  */}

          <Dialog open={open}>
            <DialogTitle>Add Timeline</DialogTitle>
            <Grid container paddingBottom={'10px'} Width="500px">
              {' '}
              <DialogContent paddingTop="20px">
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction={'column'} spacing={1} width="500px">
                    <Stack direction="column" spacing={1} justifyContent="space-between">
                      <TextField
                        type="text"
                        label="Content"
                        fullWidth
                        multiline
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                      />
                      <Box
                        sx={{
                          boxShadow: '#6E8AEE 0px 1px 4px',
                          borderRadius: '10px',
                          padding: 1,
                          backgroundColor: '#F1F5F9',
                        }}
                      >
                        <FilePond
                          files={files}
                          onupdatefiles={setFiles}
                          allowMultiple
                          maxFiles={10}
                          credits={false}
                          // server="/api"
                          name="files"
                          labelIdle="Drag & Drop images"
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>
                <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                  <Button onClick={handlePopup}>Cancel</Button>
                  <LoadingButton loading={isSubmitting} type="submit" onClick={handleCreate} disabled={!content}>
                    Add Timeline
                  </LoadingButton>
                </Stack>
              </DialogContent>
            </Grid>
          </Dialog>

          {/* edit dialog  */}

          <Dialog open={open2}>
            <DialogTitle>Edit Timeline</DialogTitle>
            <Grid container paddingBottom={'10px'} Width="500px">
              {' '}
              <DialogContent paddingTop="20px">
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction={'column'} spacing={1} width="500px">
                    <Stack direction="column" spacing={1} justifyContent="space-between">
                      <TextField
                        type="text"
                        label="Content"
                        fullWidth
                        multiline
                        value={selectedTimeline.content}
                        defaultValue={selectedTimeline.content}
                        onChange={(e) => setSelectedTimeline({ ...selectedTimeline, content: e.target.value })}
                      />
                      <Box
                        sx={{
                          boxShadow: '#6E8AEE 0px 1px 4px',
                          borderRadius: '10px',
                          padding: 1,
                          backgroundColor: '#F1F5F9',
                        }}
                      >
                        {selectedTimeline.files?.map((item, index) => (
                          <img src={item.fileURL} width="100px" alt="preview images" />
                        ))}

                        <FilePond
                          files={editfiles}
                          onupdatefiles={setEditFiles}
                          allowMultiple
                          maxFiles={10}
                          credits={false}
                          // server="/api"
                          name="files"
                          labelIdle="Drag & Drop images"
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>
                <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                  <Button onClick={handleEditPopup}>Cancel</Button>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    onClick={handleEdit}
                    disabled={!selectedTimeline.content}
                  >
                    Edit Timeline
                  </LoadingButton>
                </Stack>
              </DialogContent>
            </Grid>
          </Dialog>

          {/* popup ends  */}
        </Container>
      )}
    </>
  );
}
