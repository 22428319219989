const hasAccessOf = (path = '') => {
  let accessList = JSON.parse(localStorage.getItem('profile')).accessList;
  try {
    const keys = path.split('#');
    for (let i = 0; i < keys.length; i += 1) {
      if (accessList[keys[i]]) {
        accessList = accessList[keys[i]];
      } else {
        return false;
      }
    }
    return true;
  } catch (error) {
    return false;
  }
};

export default hasAccessOf;
