import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Box,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { useEffect, useState } from 'react';
import hasAccessOf from '../store';
import { CustomButton } from '../theme/CustomButton';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/JobPostFilter';
import Popup from '../components/popups/JobPostStatusChange';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function JobPost() {
  const navigate = useNavigate();
  const [jobPostData, setJobPostData] = useState();
  const [pages, setPages] = useState(0);
  const [jobPostCount, setJobPostCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [jobType, setJobType] = useState('ALL');
  const [isEnabled, setIsEnabled] = useState('ALL');
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();

  const getJobPosts = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/jobpost/all`, {
        params: options,
      });
      console.log('jobPost posts', res); 
      setJobPostData(res.data.data.jobPosts);
      setJobPostCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getJobPosts(pages);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getJobPosts(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(jobPostCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getJobPosts(temp);
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isEnabled}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.jobPostId.length) {
      temp.jobPostId = filter.jobPostId;
    }
    if (filter.jobType.length) {
      temp.jobType = filter.jobType;
    }
    if (filter.isEnabled.length) {
      temp.isEnabled = filter.isEnabled;
    }
    if (filter.title.length) {
      temp.title = filter.title;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }

    setOptions(temp);
    setPages(0);
    getJobPosts(temp);
  };

  function getPriorityLabel(priority) {
    switch (priority) {
      case 0:
        return 'NO PRIORITY';
      case 1:
        return 'LOW';
      case 2:
        return 'MEDIUM';
      case 3:
        return 'HIGH';
      default:
        return '';
    }
  }

  useEffect(() => {
    getJobPosts(options);
  }, []);

  return (
    hasAccessOf('jobs#job') && (
      <Page title="Jobs">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between">
               <Typography variant="h4" mb={4}>Job Posts</Typography>
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </CustomButton>
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <CustomButton
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      variant="contained"
                      onClick={() => navigate('/dashboard/jobpost/add')}
                    >
                      Add Job
                    </CustomButton>
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      {/* <JobPostListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Posted On</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>JobPost ID</TableCell>
                          <TableCell>Job Type</TableCell>
                          <TableCell>Priority</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobPostData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell style={{ width: '80px' }}>{pages * size + (id + 1)}</TableCell>
                            <TableCell style={{ width: '120px' }}>
                              {Moment(item.createdAt).format('DD-MMM-YYYY')}
                            </TableCell>
                            <TableCell style={{ minWidth: '30%' }}>{item.title}</TableCell>
                            <TableCell style={{ width: '80px', cursor: 'pointer' }}>
                              <Stack direction="column" spacing={0.5} width="80px">
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('JobPost id copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '18px', width: '18px', color: '#192B6B' }}
                                    />
                                    <Typography sx={{ color: '#192B6B' }} variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>
                            <TableCell>{item?.jobType?.replaceAll('_', ' ')}</TableCell>
                            <TableCell>
                              {getPriorityLabel(item.priority)}
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item.isEnabled}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="true">ENABLED</MenuItem>
                                  <MenuItem value="false">DISABLED</MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Link to={`/dashboard/jobpost/view/${item._id}`}>
                                  <Icon sx={{ color: 'gray' }}>
                                    <Iconify icon="subway:eye" />
                                  </Icon>
                                </Link>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  showFirstButton
                  showLastButton
                  count={jobPostCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    )
  );
}
