import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import hasAccessOf from '../store';
// sections
import { AppWidgetSummary, BarGraph } from '../sections/@dashboard/app';

const rfqStatusOrder = ['Pending', 'Ongoing', 'Decision Pending', 'Interested', 'Not Interested', 'Regret'];

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();

  const [counts, setCounts] = useState({});

  const [leadBarGraphData, setLeadBarGraphData] = useState({ chartLabels: [], chartData: [] });

  const [customerBarGraphData, setCustomerBarGraphData] = useState({ chartLabels: [], chartData: [] });

  const [leadCountByStatus, setLeadCountByStatus] = useState([]);

  const getCounts = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/lead/summary`);
      setCounts(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/lead/bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setLeadBarGraphData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadCountByStatus = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/lead/count/status`);
      if (!res.data.isError && res?.data?.data?.data?.length > 0) {
        //  sort by status order also add missig status with total 0
        const sortedData = rfqStatusOrder.map((status) => {
          const item = res.data.data.data.find((item) => item.status === status);
          return item || { status, total: 0 };
        });
        setLeadCountByStatus(sortedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/customer/bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setCustomerBarGraphData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCounts();
    getLeadCountByStatus();
  }, []);

  return (
    hasAccessOf('dashboard') && (
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Grid container spacing={3} rowGap={4}>
            <Grid item xs={12}>
             <Typography variant="h4" mb={4}>Hi, Welcome Back</Typography>
            </Grid>

            {/* lead counts  by status */}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Leads By Status</Typography>
                </Grid>
                <>
                  {leadCountByStatus.map((item) => (
                    <Grid item xs={12} sm={6} md={3} lg={2} key={item.status}>
                      <AppWidgetSummary
                        title={item.status}
                        total={item.total}
                        color="navy"
                        // icon={'ant-design:android-filled'}
                      />
                    </Grid>
                  ))}
                </>
              </Grid>
            </Grid>
            {/* lead counts  by status end */}

            <Grid item xs={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Leads</Typography>
                </Grid>
                <Grid item xs={12}>
                  <BarGraph
                    unit="Leads"
                    callAPI={getLeadBarGraphData}
                    // subheader="(+43%) than last year"
                    xaxisPlot={'category'}
                    chartLabels={[...leadBarGraphData.chartLabels]}
                    chartData={[
                      {
                        name: 'Leads',
                        type: 'column',
                        fill: 'solid',
                        data: [...leadBarGraphData.chartData],
                        color: theme.palette.balanced.main,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Lead summary */}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Lead Summary</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <AppWidgetSummary
                    title="Today"
                    total={counts?.today}
                    color="navy"
                    // icon={'ant-design:android-filled'}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <AppWidgetSummary
                    title="Week"
                    total={counts?.week}
                    color="navy"
                    // icon={'ant-design:apple-filled'}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <AppWidgetSummary
                    title="Month"
                    total={counts?.month}
                    color="navy"
                    // icon={'ant-design:windows-filled'}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <AppWidgetSummary
                    title="Year"
                    total={counts?.year}
                    color="navy"
                    // icon={'ant-design:bug-filled'}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2.4}> 
                  <AppWidgetSummary
                    title="Total"
                    total={counts?.total}
                    color="navy"
                    // icon={'ant-design:bug-filled'}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Customers</Typography>
                </Grid>
                <Grid item xs={12}>
                  <BarGraph
                    unit="Customers"
                    xaxisPlot={'category'}
                    callAPI={getCustomerBarGraphData}
                    chartLabels={[...customerBarGraphData.chartLabels]}
                    chartData={[
                      {
                        name: 'Customers',
                        type: 'column',
                        fill: 'solid',
                        data: [...customerBarGraphData.chartData],
                        color: theme.palette.bluee.main,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    )
  );
}
