import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import AddBlogPost from './pages/AddBlogPost';
import ViewBlogPost from './pages/ViewBlogPost';
import JobPost from './pages/JobPosts';
import AddJobPost from './pages/AddJobPost';
import ViewJobPost from './pages/ViewJobPost';
import ChatView from './components/email-views/ChatView';
import Accounts from './pages/Accounts';
import AddAccount from './pages/AddAccount';
import ViewAccount from './pages/ViewAccount';
import ContactUsView from './components/email-views/ContactUsView';
import DemoView from './components/email-views/DemoView';
import Resumes from './pages/Resumes';
import ViewResume from './components/category-lists/ViewResume';
import Testimonials from './pages/Testimonials';
import JobExpo from './pages/JobExpo';
import Questions from './pages/Questions';
import QuestionPapers from './pages/QuestionPapers';
import CreateQuestionPaper from './pages/CreateQuestionPaper';
import ViewQuestionPaper from './pages/ViewQuestionPaper';
import Answers from './pages/Answers';
import Candidate from './pages/Candidate';
import ViewAnswerPaper from './pages/ViewAnswerPaper';
import Demo from './pages/Demo';
import ContactUs from './pages/ContactUs';
import Chat from './pages/Chat';
import Settings from './pages/Settings';
import Customers from './pages/Customers';
import Leads from './pages/Leads';
import AddLead from './pages/AddLead';
import CrmSettings from './pages/CrmSettings';
import ViewLead from './pages/ViewLead';
import SalesStaffs from './pages/SalesStaffs';
import ViewCustomer from './pages/ViewCustomer';
import Employees from './pages/Employees';
import AddEmployee from './pages/AddEmployee';
import ViewSalesStaff from './pages/ViewSalesStaff';
import ViewSalesManager from './pages/ViewSalesManager';
import SalesManagers from './pages/SalesManagers';
import ViewProfile from './pages/ViewProfile';
import CrmReports from './pages/CrmReports';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/dashboard',
      element: token ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'profile', element: <ViewProfile /> },
        { path: 'employees', element: <Employees /> },
        { path: 'employees/add', element: <AddEmployee /> },
        { path: 'employees/view/:id', element: <ViewAccount /> },
        { path: 'crm/salesStaffs', element: <SalesStaffs /> },
        { path: 'crm/salesStaffs/view/:id', element: <ViewSalesStaff /> },
        { path: 'crm/salesManagers', element: <SalesManagers /> },
        { path: 'crm/salesManagers/view/:id', element: <ViewSalesManager /> },
        { path: 'crm/customers', element: <Customers /> },
        { path: 'crm/customers/view/:id', element: <ViewCustomer /> },
        { path: 'crm/leads', element: <Leads /> },
        { path: 'crm/leads/add', element: <AddLead /> },
        { path: 'crm/leads/view/:id', element: <ViewLead /> },
        { path: 'crm/reports', element: <CrmReports /> },
        { path: 'crm/settings', element: <CrmSettings /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'blog/add', element: <AddBlogPost /> },
        { path: 'blog/view/:id', element: <ViewBlogPost /> },
        { path: 'settings', element: <Settings /> },
        { path: 'job', element: <JobPost /> },
        { path: 'jobpost/add', element: <AddJobPost /> },
        { path: 'jobpost/view/:id', element: <ViewJobPost /> },
        { path: 'demo', element: <Demo /> },
        { path: 'contactUs', element: <ContactUs /> },
        { path: 'chat', element: <Chat /> },
        { path: 'resumes', element: <Resumes /> },
        { path: 'resumes/view/:id', element: <ViewResume /> },
        { path: 'chat/view/:id', element: <ChatView /> },
        { path: 'contactus/view/:id', element: <ContactUsView /> },
        { path: 'demo/view/:id', element: <DemoView /> },
        { path: 'account', element: <Accounts /> },
        { path: 'account/add', element: <AddAccount /> },
        { path: 'account/view/:id', element: <ViewAccount /> },
        { path: 'testimonial', element: <Testimonials /> },
        { path: 'event/jobexpo', element: <JobExpo /> },
        { path: 'event/questions', element: <Questions /> },
        { path: 'event/questionpapers', element: <QuestionPapers /> },
        { path: 'event/questionpapers/new', element: <CreateQuestionPaper /> },
        { path: 'event/questionpapers/view/:id', element: <ViewQuestionPaper /> },
        { path: 'event/answers', element: <Answers /> },
        { path: 'event/answers/view/:id', element: <ViewAnswerPaper /> },
        { path: 'event/candidates', element: <Candidate /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: token ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: 'login', element: token ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
