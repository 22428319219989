import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';

// material
import {
  Card,
  Stack,
  Button,
  CircularProgress,
  Container,
  TextField,
  Grid,
  Autocomplete,
  Typography,
} from '@mui/material';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';

function AddLead() {
  const navigate = useNavigate();

  // for add lead
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [othersId, setOthersId] = useState('');
  const [open1, setOpen1] = useState(false);

  const [salesManagers, setLeadManagers] = useState([]);
  const [salesManagersLoading, setLeadManagersLoading] = useState(true);
  const [salesStaffs, setSalesStaffs] = useState([]);
  const [salesStaffsLoading, setSalesStaffsLoading] = useState(true);

  const leadSchema = Yup.object().shape({
    fullname: Yup.string().required('Name is required'),
    source: Yup.string().required('Source is required'),
    category: Yup.array().min(1, 'Category is required'),
    salesManager: Yup.string().required('Sales Manager is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      title: '',
      email: '',
      phone: '',
      address: '',
      salesManager: '',
      source: '',
      category: [],
      companyName: '',
      businessType: '',
      otherSource: '',
      createdAt: moment().format('YYYY-MM-DD'),
    },
    validationSchema: leadSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/lead`, values);
        if (!res.data.isError) {
          toast.success(res.data.message);
          navigate(-1);
        } else {
          toast.error(res.data.message);
        }
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  const getLeadManager = async () => {
    try {
      setLeadManagersLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/manager/list`);
      console.log('lead managers list', res.data.data.records);
      if (!res.data.isError) {
        if (res.data.data.records) {
          setLeadManagers(res.data.data.records);
        }
      }
      setLeadManagersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSalesStaffs = async (managerId) => {
    try {
      setSalesStaffsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/manager/salesStaffs/all`, {
        params: {
          managerId,
        },
      });
      console.log('salesStaffs list', res.data.data);
      if (!res.data.isError) {
        if (res.data.data.salesStaffs) {
          setSalesStaffs(res.data.data.salesStaffs);
        }
      }
      setSalesStaffsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSources = async () => {
    try {
      setSourcesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadSource/all/active`);
      console.log('sources list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setSources(res.data.data.status);
          const others = res.data.data.status.filter((item) => item.name === 'Other')[0];
          console.log(others);
          if (others) {
            setOthersId(others._id);
          }
        }
      }
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      setCategoriesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadCategory/all/active`);
      console.log('categories list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setCategories(res.data.data.status);
        }
      }
      setCategoriesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSources();
    getCategories();
    getLeadManager();
  }, []);

  useEffect(() => {
    if (values.salesManager) {
      getSalesStaffs(values.salesManager);
    } else {
      setSalesStaffs([]);
      formik.setFieldValue('salesStaff', '');
    }
  }, [values.salesManager]);

  return (
    <Page title="Add Lead">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
          <PageHeader title="Add Lead" />
        </Stack>
        <Card>
          <Stack padding={2}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      label="Fullname"
                      {...getFieldProps('fullname')}
                      error={Boolean(touched.fullname && errors.fullname)}
                      helperText={touched.fullname && errors.fullname}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Title"
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type='date'
                      label="Date"
                      {...getFieldProps('createdAt')}
                      error={Boolean(touched.createdAt && errors.createdAt)}
                      helperText={touched.createdAt && errors.createdAt}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField 
                      fullWidth
                      label="Company Name"
                      {...getFieldProps('companyName')}
                      error={Boolean(touched.companyName && errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Business Type"
                      {...getFieldProps('businessType')}
                      error={Boolean(touched.businessType && errors.businessType)}
                      helperText={touched.businessType && errors.businessType}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      {...getFieldProps('phone')}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      label="Address"
                      {...getFieldProps('address')}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="asynchronous-demo"
                      fullWidth
                      open={open3}
                      onOpen={() => {
                        setOpen3(true);
                      }}
                      onClose={() => {
                        setOpen3(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option.name}
                      options={sources}
                      loading={sourcesLoading}
                      onChange={(_, value) => {
                        if (value) {
                          formik.setFieldValue('source', value._id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          error={Boolean(touched.source && errors.source)}
                          helperText={touched.source && errors.source}
                          label="Source"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {sourcesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {values.source && values.source === othersId && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Mention other source"
                        {...getFieldProps('otherSource')}
                        error={Boolean(touched.otherSource && errors.otherSource)}
                        helperText={touched.otherSource && errors.otherSource}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="asynchronous-demo"
                      multiple
                      fullWidth
                      open={open4}
                      onOpen={() => {
                        setOpen4(true);
                      }}
                      onClose={() => {
                        setOpen4(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option.name}
                      options={categories}
                      loading={categoriesLoading}
                      onChange={(_, value) => {
                        if (value) {
                          const category = value.map((item) => item._id);
                          formik.setFieldValue('category', category);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label="Category"
                          error={Boolean(touched.category && errors.category)}
                          helperText={touched.category && errors.category}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {categoriesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="asynchronous-demo"
                      fullWidth
                      open={open1}
                      onOpen={() => {
                        setOpen1(true);
                      }}
                      onClose={() => {
                        setOpen1(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                      getOptionLabel={(option) => option.fullname}
                      options={salesManagers}
                      loading={salesManagersLoading}
                      onChange={(_, value) => {
                        if (value) {
                          formik.setFieldValue('salesManager', value._id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.salesManager && errors.salesManager)}
                          helperText={touched.salesManager && errors.salesManager}
                          label="Sales Manager"
                          required
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {salesManagersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="asynchronous-demo"
                      fullWidth
                      open={open2}
                      onOpen={() => {
                        setOpen2(true);
                      }}
                      onClose={() => {
                        setOpen2(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                      getOptionLabel={(option) => option.fullname}
                      options={salesStaffs}
                      loading={salesStaffsLoading}
                      onChange={(_, value) => {
                        if (value) {
                          formik.setFieldValue('salesStaff', value._id);
                        } else {
                          formik.setFieldValue('salesStaff', '');
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.salesStaff && errors.salesStaff)}
                          helperText={touched.salesStaff && errors.salesStaff}
                          label="Sales Staff"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {salesStaffsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" spacing={2}>
                  <Button onClick={() => navigate(-1)}>Cancel</Button>
                  <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                    Submit
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Stack>
        </Card>
      </Container>
    </Page>
  );
}

export default AddLead;
