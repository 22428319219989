import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import axios from 'axios';

const Popup = (props) => {
  const updateStatus = () => {
    let remark = '';
    if (!props.item.isBlocked) {
      remark = document.getElementById('remark').value;
      console.log(remark);
    }
    axios
      .put(`${process.env.REACT_APP_API_URL}/employee/status`, {
        id: props.item._id,
        status: !props.item.isBlocked,
        remark: remark || ' ',
      })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        props.handleClose(
          false,
          err && err.response && err.response.data && err.response.data.message
            ? err.data.response.message
            : err.message
        );
      });
  };
  const open = true;
  return (
    <>
      <Dialog onClose={props.handleClose} open={open}>
        <DialogTitle>Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!props.item.isBlocked
              ? 'Are you sure you want to block the user ?'
              : 'Are you sure you want to unblock the user ? '}
          </DialogContentText>
          {!props.item.isBlocked && (
            <TextField autoFocus margin="dense" id="remark" label="Remark" type="text" fullWidth variant="standard" />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={updateStatus}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Popup;
