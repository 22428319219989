import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Autocomplete,
  Avatar,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../Iconify';

const LeadToExistiongCustomer = ({ lead, callApi }) => {
  const [open, setOpen] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCustomers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/customer/phone-email/all`, {
        params: {
          phone: lead.phone,
          email: lead.email,
        },
      });
      console.log(res.data.data);
      setCustomers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (lead && (lead.phone || lead.email)) getCustomers();
  }, [lead]);

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" onClick={handleClickOpen} sx={{ height: '30px' }}>
            Add
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Lead to Exisiting Customer</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ mb: 2 }}>
                <Typography mb={2}>Select Customer</Typography>
              </DialogContentText>
              <Formik
                initialValues={{
                  leadId: lead?._id,
                  customerId: null,
                }}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await axios.put(`${process.env.REACT_APP_API_URL}/customer/lead`, values);
                    actions.setSubmitting(false);
                    toast.success(response.data.message);
                    handleClose();
                    callApi();
                  } catch (error) {
                    console.log(error);
                    actions.setSubmitting(false);
                    toast.error(error.response.data.message);
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Stack sx={{ width: { xs: '100%', sm: '300px' } }}>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        onChange={(_, value) => {
                          console.log('Selected Value:', value);
                          formik.setFieldValue('customerId', value ? value._id : null);
                        }}
                        isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                        getOptionLabel={(option) => option.fullname}
                        getOptionSelected={(option, value) => option.fullname === value.fullname}
                        options={customers}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer"
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(formik.touched.manager && formik.errors.manager)}
                            helperText={formik.touched.manager && formik.errors.manager}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.fullname}
                                </Typography>{' '}
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  {option.companyName}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  {option.email}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  {option.phone}
                                </Typography>
                              </Stack>
                            </Stack>
                          </li>
                        )}
                      />
                    </Stack>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                        Submit
                      </LoadingButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default LeadToExistiongCustomer;
