import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, CircularProgress, Dialog, IconButton, Stack, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function AssignManager({ getsalesStaffsManager }) {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [managerId, setManagerId] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getManagers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/manager/list`);
      console.log('managers list', res.data.data);
      if (res.data.isError) {
        toast.error(res.data.message);
      } else {
        const temp = res.data.data.records.filter((item) => item._id !== id);
        setManagers(temp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addToTeam = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/leadTeam/salesStaff/add`, {
        salesStaffId: id,
        managerId,
      });
      getsalesStaffsManager();
      toast(res.data.message);
      handleClose();
      setButtonLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    getManagers();
  }, []);

  return (
    <Box>
      <Box id="basic-info" marginBottom={1}>
        <Box
          sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
          paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
          paddingY={1}
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Button variant="contained" onClick={() => setOpen(true)}>
              Assign Manager
            </Button>
          </Stack>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Box padding={2}>
          <Stack direction="column" spacing={3} alignItems="center" justifyContent="center">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: '100%',
              }}
            >
              <h3>Assign Manager</h3>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Stack>
            <Autocomplete
              id="asynchronous-demo"
              fullWidth
              open={open1}
              onOpen={() => {
                setOpen1(true);
              }}
              onClose={() => {
                setOpen1(false);
              }}
              isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
              getOptionLabel={(option) => option.fullname}
              options={managers}
              loading={loading}
              onChange={(_, value) => setManagerId(value._id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sales Manager"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <LoadingButton loading={buttonLoading} onClick={addToTeam} variant="contained">
              Assign Manager
            </LoadingButton>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  );
}
