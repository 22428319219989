import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EmployeeBasicInfo from '../components/EmployeeBasicInfo';
import Page from '../components/Page';
import AssignManager from '../components/AssignManager';

import SalesStaffDetailPageTabs from '../components/cardTabs/SalesStaffDetailPageTabs';
import UpdateManager from '../components/UpdateManager';

export default function ViewSalesStaff() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [salesStaff, setsalesStaff] = useState({});
  const [salesStaffsManager, setsalesStaffsManager] = useState({});

  const getEmployee = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/employee`, {
        params: {
          id,
        },
      });
      setsalesStaff(res.data?.data?.employeeAccount);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getsalesStaffsManager = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/salesStaff/manager`, {
        params: {
          id,
        },
      });
      console.log('telecalersManger', res.data.data);
      if (res.data.data.managerExists) {
        setsalesStaffsManager(res.data.data.manager);
      } else {
        setsalesStaffsManager(false);
      }
    } catch (error) {
      console.log(error);
      setsalesStaffsManager(false);
    }
  };

  useEffect(() => {
    getEmployee();
    getsalesStaffsManager();
  }, [id]);

  return (
    <Page title="Sales Staff">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Box id="basic-info" marginBottom={1}>
              <EmployeeBasicInfo getEmployee={getEmployee} employee={salesStaff} />
            </Box>
            {!salesStaffsManager && <AssignManager getsalesStaffsManager={getsalesStaffsManager} />}
            {salesStaffsManager && (
              <UpdateManager getsalesStaffsManager={getsalesStaffsManager} manager={salesStaffsManager?._id} />
            )}
            <SalesStaffDetailPageTabs salesStaff={salesStaff} managerId={salesStaffsManager?._id} />
          </Stack>
        </Container>
      )}
    </Page>
  );
}
