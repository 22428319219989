import { CircularProgress, Grid, Stack, Typography, Box, Container } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Iconify from '../Iconify';
import PageHeader from '../smallComponents/PageHeader';

export default function ContactUsView() {
  const { id } = useParams();
  const [mailData, setMailData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getMailData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/maildata/contactus`, { params: { id } });
      console.log('Response', res);
      setMailData(res.data.data.emailDataDoc);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMailData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container  maxWidth="xl">
          <Grid>
            <Stack marginBottom={0.5} direction="row" justifyContent={'space-between'}>
              <PageHeader title="Contact Us Mail" />
            </Stack>
          </Grid>
          <Box
            sx={{
              boxShadow: '#6E8AEE 0px 1px 4px',
              borderRadius: '10px',
              padding: 1,
            }}
          >
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      {/* <Link to={`/dashboard/user/${mailData?._id}`}> */}
                      <Iconify icon="wpf:name" style={{ height: '30px', width: '30px', color: 'white' }} />
                      {/* </Link> */}
                    </Stack>

                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                      <Stack direction="column" sx={{ padding: 1 }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Fullname</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{mailData?.fullname?.toUpperCase()}</Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify icon="ic:baseline-email" style={{ height: '30px', width: '30px', color: 'white' }} />
                    </Stack>
                    <CopyToClipboard text={mailData?.email} onCopy={() => toast.info('Email ID Copied')}>
                      <Stack direction="column" sx={{ padding: 1, cursor: 'pointer' }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Email</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{mailData?.email}</Typography>
                      </Stack>
                    </CopyToClipboard>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify
                        icon="fa6-solid:square-phone"
                        style={{ height: '30px', width: '30px', color: 'white' }}
                      />
                    </Stack>
                    <CopyToClipboard text={mailData?.phone} onCopy={() => toast.info('Phone Number Copied')}>
                      <Stack direction="column" sx={{ padding: 1, cursor: 'pointer' }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Phone</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{mailData?.phone}</Typography>
                      </Stack>
                    </CopyToClipboard>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify icon="ic:baseline-subject" style={{ height: '30px', width: '30px', color: 'white' }} />
                    </Stack>
                    <Stack direction="column" sx={{ padding: 1 }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Subject</Typography>
                      <Typography style={{ wordBreak: 'break-all' }}>{mailData?.subject}</Typography>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify icon="bxs:category" style={{ height: '30px', width: '30px', color: 'white' }} />
                    </Stack>
                    <Stack direction="column" sx={{ padding: 1 }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Email Category</Typography>
                      <Typography style={{ wordBreak: 'break-all' }}>
                        {mailData?.emailCategory?.replaceAll('_', ' ')}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Box marginTop={2} padding={1} sx={{ border: 'solid 1px #e3dede', borderRadius: '10px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack
                      alignItems="center"
                      spacing={1}
                      direction="row"
                      sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                        padding={2}
                      >
                        <Iconify
                          icon="ic:baseline-category"
                          style={{ height: '30px', width: '30px', color: 'white' }}
                        />
                      </Stack>
                      <Stack direction="column" sx={{ padding: 1 }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Project Category</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>
                          {mailData?.contactUs?.category?.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack
                      alignItems="center"
                      spacing={1}
                      direction="row"
                      sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                        padding={2}
                      >
                        <Iconify
                          icon="carbon:location-filled"
                          style={{ height: '30px', width: '30px', color: 'white' }}
                        />
                      </Stack>
                      <Stack direction="column" sx={{ padding: 1 }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Location</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{mailData?.contactUs?.location}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack
                      alignItems="center"
                      spacing={1}
                      direction="row"
                      sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                        padding={2}
                      >
                        <Iconify
                          icon="ant-design:dollar-circle-filled"
                          style={{ height: '30px', width: '30px', color: 'white' }}
                        />
                      </Stack>
                      <Stack direction="column" sx={{ padding: 1 }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Budget</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{mailData?.contactUs?.budget}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack
                      alignItems="center"
                      spacing={1}
                      direction="row"
                      sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ height: '100%', background: '#192B6B', borderRadius: '5px 0px 0px 5px' }}
                        padding={2}
                      >
                        <Iconify
                          icon="fluent:text-description-20-filled"
                          style={{ height: '30px', width: '30px', color: 'white' }}
                        />
                      </Stack>
                      <Stack direction="column" sx={{ padding: 1 }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Description</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{mailData?.contactUs?.description}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
}
