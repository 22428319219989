import { Link, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Box,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { useEffect, useState } from 'react';
import { CustomButton } from '../theme/CustomButton';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/AccountsFilter';
import Popup from '../components/popups/AccountStatusChange';
import PageHeader from '../components/smallComponents/PageHeader';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Accounts() {
  const admin = JSON.parse(localStorage.getItem('profile')).accType === 'SUPER_ADMIN';
  console.log('admin', admin);
  const [accounts, setAccounts] = useState();
  const [pages, setPages] = useState(0);
  const [accountsCount, setAccountsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();

  const getAccounts = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/account/all`, {
        params: options,
      });
      console.log('Accounts', res);
      setAccounts(res.data.data.adminAccounts);
      setAccountsCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getAccounts(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getAccounts(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(accountsCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getAccounts(temp);
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isEnabled}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.id.length) {
      temp.id = filter.id;
    }
    if (filter.fullname.length) {
      temp.fullname = filter.fullname;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked;
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }

    setOptions(temp);
    setPages(0);
    getAccounts(temp);
  };

  useEffect(() => {
    getAccounts(options);
  }, []);

  return (
    admin && (
      <Page title="Users">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <PageHeader title="Admins" />
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </CustomButton>
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <Link style={{ textDecoration: 'none' }} to={'/dashboard/account/add'}>
                      <CustomButton startIcon={<Iconify icon="eva:plus-fill" />} variant="contained">
                        Add Account
                      </CustomButton>
                    </Link>
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Account ID</TableCell>
                          <TableCell>Fullname</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Account Type</TableCell>
                          <TableCell>Member Since</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {accounts?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            <TableCell style={{ width: '80px', cursor: 'pointer' }}>
                              <Stack direction="column" spacing={0.5} width="80px">
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('Account ID copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '18px', width: '18px', color: '#192B6B' }}
                                    />
                                    <Typography sx={{ color: '#192B6B' }} variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>
                            <TableCell>{item?.fullname?.toUpperCase()}</TableCell>
                            <TableCell>{item?.email}</TableCell>
                            <TableCell>{item?.accType?.replace('_', ' ')}</TableCell>
                            <TableCell>{Moment(item?.createdAt).format('DD-MM-YYYY')}</TableCell>
                            <TableCell>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="false">Active</MenuItem>
                                  <MenuItem value="true">Blocked</MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Link to={`/dashboard/account/view/${item._id}`}>
                                  <Icon sx={{ color: 'gray' }}>
                                    <Iconify icon="subway:eye" />
                                  </Icon>
                                </Link>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  count={accountsCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    )
  );
}
