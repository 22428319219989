import { Add, BorderColor, Close, Edit, RemoveRedEye } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import Iconify from './Iconify';
import CustomerEditForm from './CustomerEditForm';
import Popup from './popups/CustomerStatusChange';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.primary.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={profilePic ? 0 : 1}
      >
        {profilePic && (
          <Avatar src={profilePic} alt={title} style={{ height: '100px', width: '100px' }} color="primary" />
        )}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

export default function CustomerBasicInfo({ customer, getCustomer }) {
  const [popup, setPopup] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const handleStatusChange = (event, item) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    setPopup(true);
  };

  const handleClose = (refresh = false, message = '') => {
    setPopup(false);
    if (refresh) {
      getCustomer();
    }
    if (message) {
      toast(message);
    }
  };

  return (
    <>
      <>
        <Box
          sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
          paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
          paddingTop={1}
          paddingBottom={2}
        >
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2} spacing={3}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <FormControl sx={{ minWidth: 100 }}>
                  <Select size="small" value={customer?.isBlocked} onChange={(e) => handleStatusChange(e, customer)}>
                    <MenuItem value={'false'}>🟢 Active</MenuItem>
                    <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                  </Select>
                  {popup && <Popup item={customer} handleClose={handleClose} />}
                </FormControl>
              </Stack>

              <IconButton onClick={() => setEditMode(!editMode)}>{!editMode ? <BorderColor /> : <Close />}</IconButton>
            </Stack>
          </Stack>
          {!editMode && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <DetailItem icon="raphael:customer" title="Name" data={customer?.fullname} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailItem icon="mdi:company" title="Company Name" data={customer?.companyName} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailItem icon="mdi:email-outline" title="Email" data={customer?.email} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailItem icon="ri:phone-line" title="Phone" data={customer?.phone} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailItem
                  icon="material-symbols-light:business-center-sharp"
                  title="Business Type"
                  data={customer?.businessType}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailItem icon="material-symbols:person" title="Contact Person" data={customer?.contactPerson} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailItem icon="mdi:phone-outline" title="Contact Person Phone" data={customer?.contactPersonPhone} />
              </Grid>
              <Grid item xs={12}>
                <DetailItem title="Address" icon="la:address-card" data={customer?.address} />
              </Grid>

              <Grid item xs={12}>
                <DetailItem title="Notes" icon="clarity:note-solid" data={customer?.notes} />
              </Grid>
            </Grid>
          )}
          {editMode && (
            <CustomerEditForm
              customer={customer}
              getCustomer={getCustomer}
              setEditMode={setEditMode}
              editMode={editMode}
            />
          )}
        </Box>
      </>
    </>
  );
}
