import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import Page from '../components/Page';
import SalesStaffPerformance from '../components/SalesStaffPerformance';

const tabStyle = {
  '&.Mui-selected': {
    color: 'primary.main',
  },
};

export default function CrmReports() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Page title="CRM Reports">
        <Container maxWidth="xl">
          <Typography variant="h4" mb={4}>
            CRM Reports
          </Typography>
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab sx={tabStyle} label="Sales Staff Performance" value="1" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <SalesStaffPerformance />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
