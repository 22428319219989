import axios from 'axios';
// material
import {
  Stack,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';

export default function CustomerEditForm({ customer, getCustomer, setEditMode, editMode }) {
  const formik = useFormik({
    initialValues: {
      id: customer?._id,
      fullname: customer?.fullname,
      email: customer?.email,
      phone: customer?.phone,
      address: customer?.address,
      companyName: customer?.companyName,
      businessType: customer?.businessType,
      notes: customer?.notes,
      contactPerson: customer?.contactPerson,
      contactPersonPhone: customer?.contactPersonPhone,
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/customer`, values);
        if (!res.data.isError) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setEditMode(false);
        getCustomer();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fullname"
              {...getFieldProps('fullname')}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Company Name"
              {...getFieldProps('companyName')}
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={touched.companyName && errors.companyName}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Business Type"
              {...getFieldProps('businessType')}
              error={Boolean(touched.businessType && errors.businessType)}
              helperText={touched.businessType && errors.businessType}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Contact Person"
              {...getFieldProps('contactPerson')}
              error={Boolean(touched.contactPerson && errors.contactPerson)}
              helperText={touched.contactPerson && errors.contactPerson}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Contact Person Phone"
              {...getFieldProps('contactPersonPhone')}
              error={Boolean(touched.contactPersonPhone && errors.contactPersonPhone)}
              helperText={touched.contactPersonPhone && errors.contactPersonPhone}
              disabled={!editMode}
            />
          </Grid>
        
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              minRows={4}
              label="Notes"
              {...getFieldProps('notes')}
              error={Boolean(touched.notes && errors.notes)}
              helperText={touched.notes && errors.notes}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        {editMode && (
          <Stack direction={'row'} justifyContent="flex-end" spacing={1} paddingTop="20px">
            <Button variant="contained" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Update
            </LoadingButton>
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}
