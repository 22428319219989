import { useEffect, useState } from 'react';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import Iconify from './Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                jobPostId: '',
                jobType: '',
                title: '',
                startDate: '',
                endDate: '',
                isEnabled: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack
                    spacing={1.5}
                    direction="column"
                    sx={{ minWidth: { lg: '400px', md: '400px', sm: '400px', xl: '400px' } }}
                  >
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      <TextField
                        fullWidth
                        id="jobPostId"
                        name="jobPostId"
                        label="Job Post ID"
                        value={values.jobPostId}
                        onChange={handleChange}
                      />
                      <TextField
                        fullWidth
                        id="jobType"
                        name="jobType"
                        label="Job Type"
                        select
                        value={values.jobType}
                        onChange={handleChange}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="INTERNSHIP">INTERNSHIP</MenuItem>
                        <MenuItem value="FULL_TIME">FULL TIME</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      <TextField
                        fullWidth
                        id="title"
                        name="title"
                        label="Job Title"
                        value={values.title}
                        onChange={handleChange}
                      />
                      <TextField
                        sx={{ width: '170px' }}
                        select
                        id="isEnabled"
                        name="isEnabled"
                        label="Status"
                        value={values.isEnabled}
                        onChange={handleChange}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="true">ENABLED</MenuItem>
                        <MenuItem value="false">DISABLED</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack direction={'row'} spacing={2} alignItems="center">
                      <TextField
                        type="date"
                        name="startDate"
                        label="Start Date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <p style={{ paddingTop: '15px' }}>to</p>
                      <TextField
                        type="date"
                        name="endDate"
                        fullWidth
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>

                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
