import { useContext } from 'react';
import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import StateContext from '../../components/Context/Context';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${open ? 240 : 80}px)`, // Adjust width based on the 'open' state
    // width: '100%', // Adjust width based on the 'open' state
    transition: 'all 0.3s ease-in-out',
  },
  // zIndex: theme.zIndex.drawer + 100,
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { dispatch, state } = useContext(StateContext);

  const handleOpen = () => {
    dispatch({ type: 'OPEN' });
  };

  const handleCLose = () => {
    dispatch({ type: 'CLOSE' });
  };

  return (
    <RootStyle open={state}>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <IconButton
          onClick={state ? handleCLose : handleOpen}
          sx={{
            color: 'text.primary',
            display: { xs: 'none', lg: 'flex' },
          }}
        >
          <Iconify
            icon="ci:hamburger-md"
            style={{
              transform: !state ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'all 0.5s ease-in-out',
            }}
          />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}

          {/* on click the following butto */}
          {/* <Button variant="contained" color="primary" sx={{ textTransform: 'none' }}>
            Projects
          </Button> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
