import { ArrowBack, Delete, Warning } from '@mui/icons-material';
import { Box, Button, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import LeadNotes from '../components/LeadNotes';
import LeadBasicInfo from '../components/LeadBasicInfo';
import Page from '../components/Page';
import Popup1 from '../components/popups/LeadDelete';
import LeadToExistiongCustomer from '../components/popups/LeadToExistingCustomer';
import PageHeader from '../components/smallComponents/PageHeader';
import LeadNotes from '../components/LeadNotes';
// import LeadAttachments from '../components/LeadAttachments';
 
export default function ViewLead() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [lead, setLead] = useState({});
  const [selectedIndexDelete, setSelectedIndexDelete] = useState(false);
  const [customersExist, setCustomersExist] = useState(false);

  const handleDeleteLead = () => {
    setSelectedIndexDelete(true);
  };

  const handleCloseDelete = (refresh = false, message = '') => {
    setSelectedIndexDelete(false);
    if (refresh) {
      navigate('/dashboard/crm/leads');
    }
    if (message) {
      toast(message);
    }
  };

  const getLead = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/lead`, {
        params: {
          id,
        },
      });
      console.log('lead', res);
      setLead(res.data.data.lead);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const checkIfCustomersWithPhoneOrEmailExist = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/phone-email`, {
        params: {
          phone: lead.phone,
          email: lead.email,
        },
      });
      console.log('response', response);
      if (!response.data.isError) {
        setCustomersExist(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (lead && lead?.fullname) setIsLoading(false);
    if (lead && (lead.phone || lead.email) && !lead.customer) {
      checkIfCustomersWithPhoneOrEmailExist();
    } else {
      setCustomersExist(false);
    }
  }, [lead]);

  useEffect(() => {
    getLead();
  }, []);

  return (
    <Page title="View Lead">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <PageHeader title="View Lead" />
              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  size="small"
                  sx={{ height: '32px' }}
                  color="error"
                  variant="contained"
                  onClick={() => handleDeleteLead()}
                  startIcon={<Delete />}
                >
                  Delete
                </Button>
                {selectedIndexDelete && <Popup1 item={lead} handleCloseDelete={handleCloseDelete} />}
              </Stack>
            </Stack>
            <Grid container spacing={3} rowGap={4}>
              {customersExist && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      boxShadow: '#d0cfcf 0px 1px 4px',
                      borderRadius: '10px',
                      bgcolor: 'background.paper',
                      cursor: 'pointer',
                    }}
                    paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
                    paddingY={1}
                  >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Stack direction="row" spacing={2} alignItems={'center'}>
                        <Warning color="orangee" />
                        <Typography>
                          Customers with the same phone number or email address already exist. Click Add to select and
                          add the lead to an existing customer.
                        </Typography>
                      </Stack>
                      <LeadToExistiongCustomer callApi={getLead} lead={lead} />
                    </Stack>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <LeadBasicInfo lead={lead} getLead={getLead} />
              </Grid>
              <Grid item xs={12}>
                <LeadNotes getLead={getLead} />
              </Grid>
              <Grid item xs={12}>
                {/* <LeadAttachments attachments={lead?.attachments} /> */}
              </Grid>
            </Grid>
          </>
        </Container>
      )}
    </Page>
  );
}
