import { Avatar, Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import React from 'react';
import { useNavigate } from 'react-router-dom';

const customerPath = '/dashboard/general/customers/view';

CustomerProfileClick.propTypes = {
  cutomerName: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  profilePic: PropTypes.string,
};

export default function CustomerProfileClick({ cutomerName, email, id, profilePic }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (id) {
      navigate(`${customerPath}/${id}`);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        onClick={handleClick}
        src={profilePic && process.env.REACT_APP_API_URL + profilePic}
        sx={{ width: 40, height: 40, cursor: 'pointer' }}
      >
        {cutomerName[0]}
      </Avatar>
      <Stack sx={{ marginLeft: 1 }} spacing={0.2}>
        <Typography variant="subtitle2">{cutomerName}</Typography>
        <Typography variant="caption">{email}</Typography>
      </Stack>
    </Box>
  );
}
