import axios from 'axios';
import moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Icon,
  Container,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

// components
import { useEffect, useState } from 'react';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/QuestionPapersFilter';
import Popup from '../components/popups/QuestionPapersStatusChange';
import Popup1 from '../components/popups/QuestionPapersFinalizedChange';
import { CustomButton } from '../theme/CustomButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function QuestionPapers() {
  const navigate = useNavigate();
  const [questionPapersData, setQuestionPapersData] = useState();
  const [pages, setPages] = useState(0);
  const [questionPapersCount, setQuestionPapersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedQuestionPapers, setSelectedQuestionPapers] = useState({
    title: '',
    startDate: '',
    endDate: '',
    isBlocked: '',
    id: '',
  });

  const getQuestionPapers = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/questionPaper/all`, {
        params: options,
      });
      console.log('questionPapers', res);
      setQuestionPapersData(res.data.data.records);
      setQuestionPapersCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const questionPapersSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    startDate: Yup.string().required('Start Date is required'),
    endDate: Yup.string().required('End Date is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      startDate: '',
      endDate: '',
      isBlocked: true,
    },
    validationSchema: questionPapersSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/events/questionPapers`, values);
        toast(res.data.message);
        getQuestionPapers();
        resetForm();
        closeAddQuestionPapersModal();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const updateQuestionPapers = async () => {
    try {
      setEditSubmitting(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/events/questionPapers/`, {
        title: selectedQuestionPapers.title,
        startDate: selectedQuestionPapers.startDate,
        endDate: selectedQuestionPapers.endDate,
        isBlocked: selectedQuestionPapers.isBlocked,
        id: selectedQuestionPapers.id,
      });
      getQuestionPapers();
      toast(res.data.message);
      setSelectedQuestionPapers({
        title: '',
        startDate: '',
        endDate: '',
        isBlocked: '',
      });
      setOpen1(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const closeAddQuestionPapersModal = () => {
    setOpen(false);
    toast();
  };

  const handleEditQuestionPapers = (e, item) => {
    setSelectedQuestionPapers({
      title: item.title,
      startDate: item.startDate,
      endDate: item.endDate,
      isBlocked: item.isBlocked,
      id: item._id,
    });
    setOpen1(true);
  };

  const closeEditQuestionPapersModal = () => {
    setOpen1(false);
    toast();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getQuestionPapers(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(questionPapersCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getQuestionPapers(temp);
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${!item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getQuestionPapers();
    }
    if (message) {
      toast(message);
    }
  };

  const handleStatusChange1 = (event, item, index) => {
    console.log('item', item);
    if (`${!item.isFinalized}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex1(index);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.title) {
      temp.title = filter.title;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (['true', 'false'].includes(filter.isBlocked)) {
      temp.isBlocked = filter.isBlocked;
    }
    if (['true', 'false'].includes(filter.isFinalized)) {
      temp.isFinalized = filter.isFinalized;
    }
    if (filter.questionPaperId) {
      temp.questionPaperId = filter.questionPaperId;
    }
    setOptions(temp);
    setPages(0);
    getQuestionPapers(temp);
  };

  useEffect(() => {
    getQuestionPapers(options);
  }, []);

  return (
    <Page title="QuestionPapers">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <>
            {/* Add QuestionPapers Dialog */}

            {/* Add QuestionPapers dialog end */}
            {/* edit QuestionPapers Dialog */}
            <Dialog open={open1}>
              <DialogTitle>Edit QuestionPapers</DialogTitle>
              <Grid container paddingBottom={'10px'} Width="500px">
                <DialogContent paddingTop="20px">
                  <Grid item xs={12} md={12} lg={12}>
                    <Stack direction={'column'} spacing={1} width="500px">
                      <TextField
                        type="text"
                        label="QuestionPapers"
                        fullWidth
                        value={selectedQuestionPapers.title}
                        onChange={(e) =>
                          setSelectedQuestionPapers({ ...selectedQuestionPapers, title: e.target.value })
                        }
                      />
                      {/* <Stack direction="row" spacing={1} justifyContent="space-between">
                        <TextField
                          type="date"
                          label="Start Date"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={moment(selectedQuestionPapers.startDate).format('YYYY-MM-DD')}
                          onChange={(e) =>
                            setSelectedQuestionPapers({ ...selectedQuestionPapers, startDate: e.target.value })
                          }
                        />
                        <TextField
                          type="date"
                          label="End Date"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={moment(selectedQuestionPapers.endDate).format('YYYY-MM-DD')}
                          onChange={(e) =>
                            setSelectedQuestionPapers({ ...selectedQuestionPapers, endDate: e.target.value })
                          }
                        />
                      </Stack> */}
                    </Stack>
                  </Grid>
                  <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                    <Button onClick={closeEditQuestionPapersModal}>Cancel</Button>
                    <LoadingButton
                      type="submit"
                      // onClick={updateQuestionPapers}
                      loading={editSubmitting}
                    >
                      Update QuestionPapers
                    </LoadingButton>
                  </Stack>
                </DialogContent>
              </Grid>
            </Dialog>
            {/* edit QuestionPapers dialog end */}
            <Container maxWidth="xl">
              <Typography variant="h4" mb={4}>
                Question Papers
              </Typography>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </CustomButton>
                  </Stack>
                  <Stack padding={2} direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                    <CustomButton
                      onClick={() => navigate('/dashboard/event/questionpapers/new')}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      variant="contained"
                    >
                      Add Question Paper
                    </CustomButton>
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>ID</TableCell>
                          <TableCell> Title</TableCell>
                          <TableCell>Total Mark</TableCell>
                          <TableCell>Pass Mark</TableCell>
                          <TableCell>Duration</TableCell>
                          <TableCell>Finalized</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {questionPapersData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell sx={{ width: '110px' }}>{id + 1}</TableCell>
                            <TableCell style={{ cursor: 'pointer' }} sx={{ width: '130px' }}>
                              <Stack direction="column" spacing={0.5}>
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('Question Paper ID copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '18px', width: '18px', color: '#192B6B' }}
                                    />
                                    <Typography sx={{ color: '#192B6B' }} variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.totalMark}</TableCell>
                            <TableCell>{item.passMark}</TableCell>
                            <TableCell>{item.duration}</TableCell>
                            <TableCell sx={{ width: '130px' }}>
                              {!item.isFinalized && (
                                <FormControl sx={{ minWidth: 100 }}>
                                  <Select
                                    size="small"
                                    value={!item.isFinalized}
                                    onChange={(e) => handleStatusChange1(e, item, id)}
                                  >
                                    <MenuItem value="true">No</MenuItem>
                                    <MenuItem value="false">Yes</MenuItem>
                                  </Select>
                                  {selectedIndex1 === id && <Popup1 item={item} handleClose={handleClose} />}
                                </FormControl>
                              )}
                              {item.isFinalized && <>{'Yes'}</>}
                            </TableCell>
                            <TableCell sx={{ width: '130px' }}>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={!item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="true">Active</MenuItem>
                                  <MenuItem value="false">Blocked</MenuItem>
                                </Select>

                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCell>
                            <TableCell style={{ width: '100px' }}>
                              <Stack direction="row" spacing={1}>
                                {/* <Iconify
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) => handleEditQuestionPapers(e, item)}
                                icon="clarity:edit-solid"
                              /> */}
                                <Icon
                                  sx={{ color: 'gray', cursor: 'pointer' }}
                                  onClick={() => navigate(`/dashboard/event/questionpapers/view/${item._id}`)}
                                >
                                  <Iconify icon="subway:eye" />
                                </Icon>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  showFirstButton
                  showLastButton
                  count={questionPapersCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        </>
      )}
    </Page>
  );
}
