import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import { FilePond, File, registerPlugin } from 'react-filepond';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

/// editor //
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Page from '../components/Page';
import Iconify from '../components/Iconify';
import PageHeader from '../components/smallComponents/PageHeader';

export default function AddJobPost() {
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [descriptionError, setDescriptionError] = useState(false);
  const [menu, setMenu] = useState();

  const FILE_SIZE = 1 * 1024 * 1024;
  // const SUPPORTED_FORMATS = ['jpg'];

  const blogSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    jobType: Yup.string().required('Type is required'),
    experience: Yup.string().required('Experience is required'),
    qualification: Yup.string().required('Qualification is required'),
    description: Yup.string().required('Description is required').min(9, 'Description is required'),
    priority: Yup.string().required('Priority is required'),
    // assignment: Yup.mixed().test('fileSize', 'File ', (value) => value && value.size <= FILE_SIZE),
    // .test('fileFormat', 'Unsupported Format', (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      jobType: '',
      description: '',
      experience: '',
      assignment: '',
      qualification: '',
      priority: 0,
    },
    validationSchema: blogSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/jobpost/`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast(res.data.message);
        navigate('/dashboard/job');
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const getCategories = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/category/designation/active`)
      .then((res) => setMenu(res.data.data.categories))
      .catch((err) => console.log(err));
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    formik.setFieldValue('description', editorData);
    if (editorData.length > 8) {
      formik.setFieldError('description', null);
    } else {
      formik.setFieldError('description', 'Required');
    }

    getCategories();
  }, [editorState]);

  return (
    <Page title="Add Job">
      <Container maxWidth="xl">
        <Card
          sx={{
            boxShadow: '#6E8AEE 0px 1px 4px',
            borderRadius: '10px',
          }}
        >
          <Stack direction={'row'} justifyContent="space-between" padding={1}>
            <PageHeader title="Add Post" />
            <Iconify style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => navigate(-1)} icon="fe:close" />
          </Stack>
          <Box sx={{ paddingX: 1, paddingY: 2 }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={10} md={9} sm={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Position</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Position"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      >
                        {menu?.map((menuItem, key) => (
                          <MenuItem key={key} value={menuItem._id}>
                            {menuItem.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} lg={2}>
                    <TextField
                      type="text"
                      select
                      label="Job Type"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('jobType')}
                      error={Boolean(touched.jobType && errors.jobType)}
                      helperText={touched.jobType && errors.jobType}
                    >
                      <MenuItem value="INTERNSHIP">Internship</MenuItem>
                      <MenuItem value="FULL_TIME">Full Time</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="h6">Job Summary</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} lg={5} md={6} sm={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Experience"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('experience')}
                      error={Boolean(touched.experience && errors.experience)}
                      helperText={touched.experience && errors.experience}
                    />
                  </Grid>
                  <Grid item xs={12} lg={5} md={12} sm={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Qualification"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('qualification')}
                      error={Boolean(touched.qualification && errors.qualification)}
                      helperText={touched.qualification && errors.qualification}
                    />
                  </Grid>
                  <Grid item xs={8} lg={2} md={3} sm={4}>
                    <TextField
                      select
                      InputLabelProps={{ shrink: true }}
                      label="Priority"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('priority')}
                      error={Boolean(touched.priority && errors.priority)}
                      helperText={touched.priority && errors.priority}
                    >
                      <MenuItem value={'0'}>No Priority</MenuItem>
                      <MenuItem value={'1'}>Low</MenuItem>
                      <MenuItem value={'2'}>Medium</MenuItem>
                      <MenuItem value={'3'}>High</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} direction="column">
                    <Typography variant="h6">Description</Typography>
                    <Box sx={{ minHeight: '200px', border: 'solid 1px #e6e6e6', borderRadius: '10px' }} padding={1}>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        onBlur={() => setDescriptionError(true)}
                      />
                    </Box>
                    {errors.description && descriptionError && (
                      <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                        <Typography variant="caption" color="red">
                          Description is required
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      type="file"
                      label="Assignment"
                      InputLabelProps={{ shrink: true }}
                      name="assignment"
                      onChange={(event) => formik.setFieldValue('assignment', event.target.files[0])}
                      // error={Boolean(touched.assignment && errors.assignment)}
                      // helperText={touched.assignment && errors.assignment}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      onClick={() => setDescriptionError(true)}
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
