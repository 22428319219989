import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Container, Typography } from '@mui/material';
import PageHeader from '../components/smallComponents/PageHeader';

const CreateQuestionPaper = () => {
  const navigate = useNavigate();
  const [paperTitle, setPaperTitle] = useState('');
  const [sections, setSections] = useState([]);
  const [duration, setDuration] = useState(0);
  const [totalMark, setTotalMark] = useState(0);
  const [passMark, setPassMark] = useState(0);

  const handleSubmitForm = async (data) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/events/questionPaper`, data);
      toast.success(res.data.message);
      navigate('/dashboard/event/questionpapers');
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const paper = {
      title: paperTitle,
      sections,
      duration,
      totalMark,
      passMark,
      isBlocked: false,
      isFinalized: false,
    };
    handleSubmitForm(paper);
  };

  const handleAddSection = () => {
    setSections([
      ...sections,
      {
        title: '',
        questions: [],
      },
    ]);
  };

  const handleSectionTitleChange = (event, index) => {
    const newSections = [...sections];
    newSections[index].title = event.target.value;
    setSections(newSections);
    setTotalMark(calculateTotalMark(newSections));
  };

  const handleAddQuestion = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions.push({
      questionId: '',
      mark: 0,
      negativeMark: 0,
    });
    setSections(newSections);
    setTotalMark(calculateTotalMark(newSections));
  };

  const handleQuestionChange = (event, sectionIndex, questionIndex, field) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions[questionIndex][field] = event.target.value;
    setSections(newSections);
    setTotalMark(calculateTotalMark(newSections));
  };

  const handleRemoveSection = (sectionIndex) => {
    const newSections = sections.filter((_, index) => index !== sectionIndex);
    setSections(newSections);
    setTotalMark(calculateTotalMark(newSections));
  };

  const handleRemoveQuestion = (sectionIndex, questionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions = newSections[sectionIndex].questions.filter(
      (_, index) => index !== questionIndex
    );
    setSections(newSections);
    setTotalMark(calculateTotalMark(newSections));
  };

  const calculateTotalMark = (sections) => {
    let total = 0;
    sections.forEach((section) => {
      section.questions.forEach((question) => {
        // eslint-disable-next-line radix
        total += parseInt(question.mark);
      });
    });
    return total;
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ width: '100%', mx: 'auto', mt: 4, px: 2 }}>
        <PageHeader title="Create Question Paper" />
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Paper Title"
            value={paperTitle}
            onChange={(e) => setPaperTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          {sections.map((section, sectionIndex) => (
            <Box key={sectionIndex} sx={{ mb: 4, backgroundColor: 'whitesmoke', p: 2, borderRadius: 2 }}>
              <Typography variant="h4" mb={4}>
                Section {sectionIndex + 1}
              </Typography>
              <TextField
                fullWidth
                label={`Section ${sectionIndex + 1} Title`}
                value={section.title}
                onChange={(e) => handleSectionTitleChange(e, sectionIndex)}
                sx={{ mb: 2 }}
              />
              {section.questions.map((question, questionIndex) => (
                <Box key={questionIndex} sx={{ mb: 2 }}>
                  <Typography sx={{ fontWeight: 'bold' }}>Question {questionIndex + 1}</Typography>
                  <TextField
                    label="Question ID"
                    value={question.questionId}
                    onChange={(e) => handleQuestionChange(e, sectionIndex, questionIndex, 'questionId')}
                    sx={{ mr: 2, mb: 2 }}
                  />
                  <TextField
                    label="Mark"
                    type="number"
                    value={question.mark}
                    onChange={(e) => handleQuestionChange(e, sectionIndex, questionIndex, 'mark')}
                    sx={{ mr: 2 }}
                  />
                  <TextField
                    label="Negative Mark"
                    type="number"
                    value={question.negativeMark}
                    onChange={(e) => handleQuestionChange(e, sectionIndex, questionIndex, 'negativeMark')}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveQuestion(sectionIndex, questionIndex)}
                    sx={{ ml: 1 }}
                  >
                    Remove Question
                  </Button>
                </Box>
              ))}
              <Button variant="outlined" color="primary" onClick={() => handleAddQuestion(sectionIndex)} sx={{ mb: 2 }}>
                Add Question
              </Button>
              <Button variant="outlined" color="error" onClick={() => handleRemoveSection(sectionIndex)} sx={{ mb: 2 }}>
                Remove Section
              </Button>
            </Box>
          ))}

          <Box sx={{ mt: 2 }}>
            <Button variant="outlined" color="secondary" onClick={handleAddSection}>
              Add Section
            </Button>
          </Box>

          <Box sx={{ mt: 4 }}>
            <TextField
              label="Duration (minutes)"
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              sx={{ mr: 2 }}
            />
            <TextField
              label="Total Mark"
              type="number"
              value={totalMark}
              InputProps={{
                readOnly: true,
              }}
              sx={{ mr: 2 }}
            />
            <TextField label="Pass Mark" type="number" value={passMark} onChange={(e) => setPassMark(e.target.value)} />
          </Box>

          <Box sx={{ mt: 4 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default CreateQuestionPaper;
