import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  DialogTitle,
  Box,
  Button,
  Checkbox,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '../components/Page';
import Iconify from '../components/Iconify';
import PageHeader from '../components/smallComponents/PageHeader';

export default function ViewAccount() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categoriesChecked, setCategoriesChecked] = useState([false, false, false]);
  const [emailsChecked, setEmailsChecked] = useState([false, false, false]);
  const [blogChecked, setBlogChecked] = useState([false]);
  const [jobChecked, setJobChecked] = useState([false]);
  const [eventsChecked, setEventsChecked] = useState([false, false, false, false, false]);
  const [testimonialChecked, setTestimonialChecked] = useState([false]);
  const [resumeChecked, setResumeChecked] = useState([false]);
  const [crmChecked, setCrmChecked] = useState([false, false, false, false, false]);
  const [accountsChecked, setAccountsChecked] = useState([false]);
  const [adminAccount, setAdminAccount] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const accountSchema = Yup.object().shape({
    // fullname: Yup.string().required('Fullname is required'),
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().min(8, 'Must have atleast 8 characters'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: adminAccount?.fullname,
      email: adminAccount?.email,
      password: '',
      product: false,
      contactUsCategory: false,
      designation: false,
      blog: false,
      job: false,
      chat: false,
      contactUsMail: false,
      demo: false,
      resume: false,
      testimonial: false,
      jobExpo: false,
      questions: false,
      questionPapers: false,
      candidates: false,
      answers: false,
      salesStaffs: false,
      salesManagers: false,
      leads: false,
      customers: false,
      settings: false,
      account: false,
    },
    validationSchema: accountSchema,
    onSubmit: async () => {
      console.log(values);
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/account`, { ...values, id });
        toast(res.data.message);
        formik.resetForm();

        getAccount();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const getAccount = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/account`, { params: { id } });
      console.log('Admin Account', res.data.data.adminAccount);
      setAdminAccount(res.data.data.adminAccount);
      setCategoriesChecked([
        res.data.data.adminAccount.accessList.categories.product,
        res.data.data.adminAccount.accessList.categories.contactUs,
        res.data.data.adminAccount.accessList.categories.designation,
      ]);
      setBlogChecked([res.data.data.adminAccount.accessList.blogs.blog]);
      setJobChecked([res.data.data.adminAccount.accessList.jobs.job]);
      setEmailsChecked([
        res.data.data.adminAccount.accessList.mails.chat,
        res.data.data.adminAccount.accessList.mails.contactUs,
        res.data.data.adminAccount.accessList.mails.demo,
      ]);
      setEventsChecked([
        res.data.data.adminAccount.accessList.events.jobExpo,
        res.data.data.adminAccount.accessList.events.questions,
        res.data.data.adminAccount.accessList.events.questionPapers,
        res.data.data.adminAccount.accessList.events.answers,
        res.data.data.adminAccount.accessList.events.candidates,
      ]);
      setResumeChecked([res.data.data.adminAccount.accessList.resumes.resume]);
      setTestimonialChecked([res.data.data.adminAccount.accessList.testimonials.testimonial]);
      setCrmChecked([
        res.data.data.adminAccount.accessList.crm.salesManagers,
        res.data.data.adminAccount.accessList.crm.salesStaffs,
        res.data.data.adminAccount.accessList.crm.leads,
        res.data.data.adminAccount.accessList.crm.customers,
        res.data.data.adminAccount.accessList.crm.settings,
      ]);
      setAccountsChecked([res.data.data.adminAccount.accessList.accounts.account]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const changeCategories = (event) => {
    setCategoriesChecked([event.target.checked, event.target.checked, event.target.checked]);
  };
  const changeCategoriesProduct = (event) => {
    setCategoriesChecked([event.target.checked, categoriesChecked[1], categoriesChecked[2]]);
  };
  const changeCategoriesContactUs = (event) => {
    setCategoriesChecked([categoriesChecked[0], event.target.checked, categoriesChecked[2]]);
  };
  const changeCategoriesDesignation = (event) => {
    setCategoriesChecked([categoriesChecked[0], categoriesChecked[1], event.target.checked]);
  };

  const changeEvents = (event) => {
    setEventsChecked([
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
    ]);
  };

  const changeCrm = (event) => {
    setCrmChecked([
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
    ]);
  };

  const changeAccounts = (event) => {
    setAccountsChecked([event.target.checked]);
  };

  const changeEvents1 = (event, index) => {
    eventsChecked[index] = event.target.checked;
    setEventsChecked([...eventsChecked]);
  };

  const changeCrm1 = (event, index) => {
    crmChecked[index] = event.target.checked;
    setCrmChecked([...crmChecked]);
  };

  const changeAccounts1 = (event, index) => {
    accountsChecked[0] = event.target.checked;
    setAccountsChecked([...accountsChecked]);
  };

  const changeBlogs = (event) => {
    setBlogChecked([event.target.checked]);
  };
  const changeBlog = (event) => {
    setBlogChecked([event.target.checked]);
  };
  const changeJobs = (event) => {
    setJobChecked([event.target.checked]);
  };
  const changeJob = (event) => {
    setJobChecked([event.target.checked]);
  };
  const chnageResumes = (event) => {
    setResumeChecked([event.target.checked]);
  };
  const chnageResume = (event) => {
    setResumeChecked([event.target.checked]);
  };
  const changeTestimonials = (event) => {
    setTestimonialChecked([event.target.checked]);
  };
  const changeTestimonial = (event) => {
    setTestimonialChecked([event.target.checked]);
  };

  const changeEmails = (event) => {
    setEmailsChecked([event.target.checked, event.target.checked, event.target.checked]);
  };
  const changeEmailsChat = (event) => {
    setEmailsChecked([event.target.checked, emailsChecked[1], emailsChecked[2]]);
  };
  const changeEmailsContactUs = (event) => {
    setEmailsChecked([emailsChecked[0], event.target.checked, emailsChecked[2]]);
  };
  const changeEmailsDemo = (event) => {
    setEmailsChecked([emailsChecked[0], emailsChecked[1], event.target.checked]);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    formik.setFieldValue('product', categoriesChecked[0]);
    formik.setFieldValue('contactUsCategory', categoriesChecked[1]);
    formik.setFieldValue('designation', categoriesChecked[2]);
  }, [categoriesChecked]);
  useEffect(() => {
    formik.setFieldValue('blog', blogChecked);
  }, [blogChecked]);
  useEffect(() => {
    formik.setFieldValue('job', jobChecked);
  }, [jobChecked]);
  useEffect(() => {
    formik.setFieldValue('resume', resumeChecked);
  }, [resumeChecked]);
  useEffect(() => {
    formik.setFieldValue('chat', emailsChecked[0]);
    formik.setFieldValue('contactUsMail', emailsChecked[1]);
    formik.setFieldValue('demo', emailsChecked[2]);
  }, [emailsChecked]);

  useEffect(() => {
    formik.setFieldValue('testimonial', testimonialChecked);
  }, [testimonialChecked]);

  useEffect(() => {
    formik.setFieldValue('jobExpo', eventsChecked[0]);
    formik.setFieldValue('questions', eventsChecked[1]);
    formik.setFieldValue('questionPapers', eventsChecked[2]);
    formik.setFieldValue('answers', eventsChecked[3]);
    formik.setFieldValue('candidates', eventsChecked[4]);
  }, [eventsChecked]);

  useEffect(() => {
    formik.setFieldValue('salesManagers', crmChecked[0]);
    formik.setFieldValue('salesStaffs', crmChecked[1]);
    formik.setFieldValue('leads', crmChecked[2]);
    formik.setFieldValue('customers', crmChecked[3]);
    formik.setFieldValue('settings', crmChecked[4]);
  }, [crmChecked]);

  useEffect(() => {
    formik.setFieldValue('account', accountsChecked[0]);
  }, [accountsChecked]);

  useEffect(() => {
    getAccount();
  }, []);

  return (
    <Page title="Edit Account">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Card
              sx={{
                boxShadow: '#6E8AEE 0px 1px 4px',
                borderRadius: '10px',
              }}
            >
              <Stack direction={'row'} justifyContent="space-between" padding={1}>
                <PageHeader title="View Account" />
                <Stack direction={'row'} justifyContent="space-between" spacing={2}>
                  <Iconify
                    style={{ cursor: 'pointer', fontSize: '20px' }}
                    onClick={() => navigate(-1)}
                    icon="fe:close"
                  />
                </Stack>
              </Stack>
              <Box sx={{ paddingX: 1, paddingY: 2 }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          type="text"
                          label="Fullname"
                          fullWidth
                          defaultValue={adminAccount.fullname}
                          sx={{ width: '100%' }}
                          {...getFieldProps('fullname')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          type="email"
                          label="Email"
                          defaultValue={adminAccount.email}
                          fullWidth
                          sx={{ width: '100%' }}
                          {...getFieldProps('email')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          type={showPassword ? 'text' : 'password'}
                          label="Enter New Password"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ width: '100%' }}
                          {...getFieldProps('password')}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">Access List</Typography>
                        <Box padding={1} sx={{ border: 'solid 1px #e0dcdc', borderRadius: '10px' }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                              <FormControlLabel
                                label={<Typography sx={{ fontWeight: 'bold' }}>Categories</Typography>}
                                control={
                                  <Checkbox
                                    checked={categoriesChecked[0] && categoriesChecked[1] && categoriesChecked[2]}
                                    indeterminate={
                                      categoriesChecked[0] !== categoriesChecked[1] ||
                                      categoriesChecked[0] !== categoriesChecked[2] ||
                                      categoriesChecked[1] !== categoriesChecked[2]
                                    }
                                    onChange={changeCategories}
                                  />
                                }
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                  label="Product Category"
                                  control={
                                    <Checkbox checked={categoriesChecked[0]} onChange={changeCategoriesProduct} />
                                  }
                                />
                                <FormControlLabel
                                  label="Contact Us"
                                  control={
                                    <Checkbox checked={categoriesChecked[1]} onChange={changeCategoriesContactUs} />
                                  }
                                />
                                <FormControlLabel
                                  label="Designation"
                                  control={
                                    <Checkbox checked={categoriesChecked[2]} onChange={changeCategoriesDesignation} />
                                  }
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6} sm={6} lg={3}>
                              <FormControlLabel
                                label={<Typography sx={{ fontWeight: 'bold' }}>CRM</Typography>}
                                control={
                                  <Checkbox
                                    checked={
                                      crmChecked[0] && crmChecked[1] && crmChecked[2] && crmChecked[3] && crmChecked[4]
                                    }
                                    indeterminate={
                                      [0, 1, 2, 3, 4].some((i) => crmChecked[i]) &&
                                      ![0, 1, 2, 3, 4].every((i) => crmChecked[i])
                                    }
                                    onChange={changeCrm}
                                  />
                                }
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                  label="Sales Managers"
                                  control={<Checkbox checked={crmChecked[0]} onChange={(e) => changeCrm1(e, 0)} />}
                                />
                                <FormControlLabel
                                  label="Sales Staffs"
                                  control={<Checkbox checked={crmChecked[1]} onChange={(e) => changeCrm1(e, 0)} />}
                                />
                                <FormControlLabel
                                  label="Leads"
                                  control={<Checkbox checked={crmChecked[2]} onChange={(e) => changeCrm1(e, 1)} />}
                                />
                                <FormControlLabel
                                  label="Customers"
                                  control={<Checkbox checked={crmChecked[3]} onChange={(e) => changeCrm1(e, 2)} />}
                                />
                                <FormControlLabel
                                  label="Settings"
                                  control={<Checkbox checked={crmChecked[4]} onChange={(e) => changeCrm1(e, 3)} />}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6} sm={6} lg={3}>
                              <FormControlLabel
                                label={<Typography sx={{ fontWeight: 'bold' }}>Events</Typography>}
                                control={
                                  <Checkbox
                                    checked={
                                      eventsChecked[0] &&
                                      eventsChecked[1] &&
                                      eventsChecked[2] &&
                                      eventsChecked[3] &&
                                      eventsChecked[4]
                                    }
                                    indeterminate={
                                      [0, 1, 2, 3, 4].some((i) => eventsChecked[i]) &&
                                      ![0, 1, 2, 3, 4].every((i) => eventsChecked[i])
                                    }
                                    onChange={changeEvents}
                                  />
                                }
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                  label="Job Expo"
                                  control={
                                    <Checkbox checked={eventsChecked[0]} onChange={(e) => changeEvents1(e, 0)} />
                                  }
                                />
                                <FormControlLabel
                                  label="Questions"
                                  control={
                                    <Checkbox checked={eventsChecked[1]} onChange={(e) => changeEvents1(e, 1)} />
                                  }
                                />
                                <FormControlLabel
                                  label="Question Papers"
                                  control={
                                    <Checkbox checked={eventsChecked[2]} onChange={(e) => changeEvents1(e, 2)} />
                                  }
                                />
                                <FormControlLabel
                                  label="Answers"
                                  control={
                                    <Checkbox checked={eventsChecked[3]} onChange={(e) => changeEvents1(e, 3)} />
                                  }
                                />
                                <FormControlLabel
                                  label="Candidates"
                                  control={
                                    <Checkbox checked={eventsChecked[4]} onChange={(e) => changeEvents1(e, 4)} />
                                  }
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6} sm={6} lg={2}>
                              <FormControlLabel
                                label={<Typography sx={{ fontWeight: 'bold' }}>Blogs</Typography>}
                                control={<Checkbox checked={blogChecked[0]} onChange={changeBlogs} />}
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                  label="Blog"
                                  control={<Checkbox checked={blogChecked[0]} onChange={changeBlog} />}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2}>
                              <FormControlLabel
                                label={<Typography sx={{ fontWeight: 'bold' }}>Hiring</Typography>}
                                control={<Checkbox checked={jobChecked[0]} onChange={changeJobs} />}
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                  label="Hiring"
                                  control={<Checkbox checked={jobChecked[0]} onChange={changeJob} />}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6} sm={6} lg={3}>
                              <FormControlLabel
                                label={<Typography sx={{ fontWeight: 'bold' }}>Emails</Typography>}
                                control={
                                  <Checkbox
                                    checked={emailsChecked[0] && emailsChecked[1] && emailsChecked[2]}
                                    indeterminate={
                                      emailsChecked[0] !== emailsChecked[1] ||
                                      emailsChecked[0] !== emailsChecked[2] ||
                                      emailsChecked[1] !== emailsChecked[2]
                                    }
                                    onChange={changeEmails}
                                  />
                                }
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                  label="Chat mails"
                                  control={<Checkbox checked={emailsChecked[0]} onChange={changeEmailsChat} />}
                                />
                                <FormControlLabel
                                  label="Contact Us"
                                  control={<Checkbox checked={emailsChecked[1]} onChange={changeEmailsContactUs} />}
                                />
                                <FormControlLabel
                                  label="Demo"
                                  control={<Checkbox checked={emailsChecked[2]} onChange={changeEmailsDemo} />}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={2}>
                              <FormControlLabel
                                label={<Typography sx={{ fontWeight: 'bold' }}>Resumes</Typography>}
                                control={<Checkbox checked={resumeChecked[0]} onChange={chnageResumes} />}
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                  label="Resume"
                                  control={<Checkbox checked={resumeChecked[0]} onChange={chnageResume} />}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={2}>
                              <FormControlLabel
                                label={<Typography sx={{ fontWeight: 'bold' }}>Testimonials</Typography>}
                                control={<Checkbox checked={testimonialChecked[0]} onChange={changeTestimonials} />}
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                  label="Testimonial"
                                  control={<Checkbox checked={testimonialChecked[0]} onChange={changeTestimonial} />}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={2}>
                              <FormControlLabel
                                label={<Typography sx={{ fontWeight: 'bold' }}>Accounts</Typography>}
                                control={<Checkbox checked={accountsChecked[0]} onChange={changeAccounts} />}
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <FormControlLabel
                                  label="Account"
                                  control={<Checkbox checked={accountsChecked[0]} onChange={changeAccounts1} />}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                            Submit
                          </LoadingButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </Box>
            </Card>
          </Container>
        </>
      )}
    </Page>
  );
}
