import axios from 'axios';
import moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Switch,
  Checkbox,
  IconButton,
  Chip,
  Container,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Close } from '@mui/icons-material';

// components
import { useEffect, useState } from 'react';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/QuestionFilter';
import Popup from '../components/popups/QuestionStatusChange';
import { CustomButton } from '../theme/CustomButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Question() {
  const [questionData, setQuestionData] = useState();
  const [pages, setPages] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({
    question: '',
    optionType: '', // checkbox or radio
    isBlocked: false, // true or false
    options: [],
    answer: [],
    keywords: [],
  });
  const [optionInputs, setOptionInputs] = useState([{ value: '', isAnswer: false }]);

  const getQuestions = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/question/all`, {
        params: options,
      });
      console.log('questions', res);
      setQuestionData(res.data.data.records);
      setQuestionCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const questionSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    optionType: Yup.string().required('Option type is required'),
    isBlocked: Yup.boolean().required('Is blocked is required'),
    options: Yup.array().min(1, 'At least one option is required'),
    answer: Yup.array().min(1, 'At least one answer is required'),
    keywords: Yup.string().nullable().required('At least one keyword is required'),
  });

  const formik = useFormik({
    initialValues: {
      question: '',
      optionType: '', // checkbox or radio
      isBlocked: false, // true or false
      options: [],
      answer: [],
      keywords: [],
    },
    validationSchema: questionSchema,
    onSubmit: async () => {
      try {
        const updatedValues = {
          ...values,
          keywords: values.keywords.split(',').map((keyword) => keyword.trim()),
        };

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/events/question`, updatedValues);
        toast(res.data.message);
        getQuestions();
        resetForm();
        closeAddQuestionModal();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue, handleChange } =
    formik;

  const updateQuestion = async () => {
    try {
      setEditSubmitting(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/events/question/`, {
        title: selectedQuestion.title,
        startDate: selectedQuestion.startDate,
        endDate: selectedQuestion.endDate,
        isBlocked: selectedQuestion.isBlocked,
        id: selectedQuestion.id,
      });
      getQuestions();
      toast(res.data.message);
      setSelectedQuestion({
        question: '',
        optionType: '', // checkbox or radio
        isBlocked: false, // true or false
        options: [],
        answer: [],
        keywords: [],
      });
      setOpen1(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const closeAddQuestionModal = () => {
    setOpen(false);
    toast();
  };

  const handleEditQuestion = (e, item) => {
    setSelectedQuestion({
      question: item.question,
      optionType: item.optionType,
      isBlocked: item.isBlocked,
      options: item.options,
      answer: item.answer,
      keywords: item.keywords,
    });
    setOpen1(true);
  };

  const closeEditQuestionModal = () => {
    setOpen1(false);
    toast();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getQuestions(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(questionCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getQuestions(temp);
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${!item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getQuestions(options);
    }
    if (message) {
      toast(message);
    }
  };

  const truncateText = (text, maxLength = 150) => {
    if (text.length <= maxLength) {
      return text;
    }
    return `${text.slice(0, maxLength)}...`;
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.question.length) {
      temp.question = filter.question;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (['true', 'false'].includes(filter.isBlocked)) {
      temp.isBlocked = filter.isBlocked;
    }
    if (filter.optionType.length) {
      temp.optionType = filter.optionType;
    }
    if (filter.keywords.length) {
      temp.keywords = filter.keywords;
    }
    setOptions(temp);
    setPages(0);
    getQuestions(temp);
  };

  useEffect(() => {
    getQuestions(options);
  }, []);

  return (
    <Page title="Questions">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <>
            {/* Add Question Dialog */}
            <Dialog open={open}>
              <DialogTitle>Add Question</DialogTitle>{' '}
              <DialogContent paddingTop="20px">
                <FormikProvider value={formik}>
                  <Stack direction={'column'} spacing={1} width="500px" marginY={2}>
                    <TextField
                      type="text"
                      label="Question"
                      fullWidth
                      multiline
                      {...getFieldProps('question')}
                      error={Boolean(touched.question && errors.question)}
                      helperText={touched.question && errors.question}
                    />
                    <FormControl fullWidth error={Boolean(touched.optionType && errors.optionType)}>
                      <InputLabel>Option Type</InputLabel>
                      <Select
                        label="Option Type"
                        {...getFieldProps('optionType')}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value === 'RADIO') {
                            if (values.answer.length > 1) {
                              setFieldValue('answer', [values.answer[0]]);
                            }
                          }
                        }}
                      >
                        <MenuItem value="CHECKBOX">Checkbox</MenuItem>
                        <MenuItem value="RADIO">Radio</MenuItem>
                      </Select>
                      {touched.optionType && errors.optionType && <FormHelperText>{errors.optionType}</FormHelperText>}
                    </FormControl>
                    <FormControlLabel
                      control={
                        <Switch checked={values.isBlocked} onChange={handleChange} name="isBlocked" color="primary" />
                      }
                      label="Blocked"
                    />
                    {/* Options input */}
                    <Stack direction="column" spacing={1}>
                      <Typography variant="subtitle1">Options</Typography>
                      {values.options.map((option, index) => (
                        <TextField
                          key={index}
                          type="text"
                          label={`Option ${index + 1}`}
                          value={option}
                          onChange={(e) => {
                            const newOptions = [...values.options];
                            newOptions[index] = e.target.value;
                            setFieldValue('options', newOptions);
                          }}
                          fullWidth
                        />
                      ))}
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setFieldValue('options', [...values.options, '']);
                        }}
                      >
                        Add Option
                      </Button>
                    </Stack>
                    {values.options.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={values.answer.includes(option)}
                            onChange={(e) => {
                              if (values.optionType === 'RADIO' && e.target.checked) {
                                setFieldValue('answer', [option]);
                              } else if (values.optionType === 'CHECKBOX') {
                                if (e.target.checked) {
                                  setFieldValue('answer', [...values.answer, option]);
                                } else {
                                  setFieldValue(
                                    'answer',
                                    values.answer.filter((answer) => answer !== option)
                                  );
                                }
                              }
                            }}
                            name={`answer-${index}`}
                            color="primary"
                          />
                        }
                        label={`Mark Option ${index + 1} as answer`}
                      />
                    ))}

                    <TextField
                      type="text"
                      label="Keywords (comma separated)"
                      fullWidth
                      {...getFieldProps('keywords')}
                      error={Boolean(touched.keywords && errors.keywords)}
                      helperText={touched.keywords && errors.keywords}
                    />

                    <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                      <Button onClick={closeAddQuestionModal}>Cancel</Button>
                      <LoadingButton variant="contained" onClick={handleSubmit} loading={isSubmitting}>
                        Add
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </FormikProvider>
              </DialogContent>
            </Dialog>
            {/* Add Question dialog end */}
            {/* edit Question Dialog */}
            <Dialog open={open1} onClose={() => setOpen1(false)}>
              <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6"> Question</Typography>
                  <IconButton edge="end" color="inherit" onClick={() => setOpen1(false)} aria-label="close">
                    <Close />
                  </IconButton>
                </Stack>
              </DialogTitle>
              <Grid container paddingBottom={'10px'} Width="500px">
                <DialogContent>
                  <Stack direction={'column'} spacing={1} width="500px">
                    <TextField multiline type="text" label="Question" fullWidth value={selectedQuestion.question} />
                    <FormControl fullWidth>
                      {/* <InputLabel>Option Type</InputLabel> */}
                      {/* <Select label="Option Type" value={selectedQuestion.optionType}>
                        <MenuItem value="CHECKBOX">Checkbox</MenuItem>
                        <MenuItem value="RADIO">Radio</MenuItem>
                      </Select> */}
                      <TextField type="text" label="Option Type" fullWidth value={selectedQuestion.optionType} />
                    </FormControl>
                    <FormControlLabel
                      control={<Switch checked={selectedQuestion.isBlocked} name="isBlocked" color="primary" />}
                      label="Blocked"
                    />
                    {/* Options input */}
                    <Stack direction="column" spacing={1}>
                      <Typography variant="subtitle1">Options</Typography>
                      {selectedQuestion.options.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={selectedQuestion.answer.includes(option)}
                              name={`answer-${index}`}
                              color="primary"
                              readOnly
                            />
                          }
                          label={`${index + 1}. ${option}`}
                        />
                      ))}
                    </Stack>
                    <TextField type="text" label="Keywords" fullWidth value={selectedQuestion.keywords} />{' '}
                  </Stack>
                </DialogContent>
              </Grid>
            </Dialog>

            {/* edit Question dialog end */}

            <Container maxWidth="xl">
              <Typography variant="h4" mb={4}>
                Questions
              </Typography>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </CustomButton>
                  </Stack>
                  <Stack padding={2} direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                    <CustomButton
                      onClick={() => setOpen(true)}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      variant="contained"
                    >
                      Add Question
                    </CustomButton>
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Question ID</TableCell>
                          <TableCell>Question</TableCell>
                          <TableCell>Option Type</TableCell>
                          <TableCell>Keywords</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {questionData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell sx={{ width: '110px' }}>{id + 1}</TableCell>
                            <TableCell style={{ cursor: 'pointer' }} sx={{ width: '130px' }}>
                              <Stack direction="column" spacing={0.5}>
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('Question ID copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '18px', width: '18px', color: '#192B6B' }}
                                    />
                                    <Typography sx={{ color: '#192B6B' }} variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>
                            <TableCell>{truncateText(item.question)}</TableCell>
                            <TableCell>{item.optionType}</TableCell>
                            <TableCell>
                              {item.keywords.map((keyword, index) => (
                                <Chip
                                  key={index}
                                  label={keyword}
                                  variant="outlined"
                                  size="small"
                                  style={{ marginRight: '5px' }}
                                />
                              ))}
                            </TableCell>

                            <TableCell sx={{ width: '130px' }}>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={!item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="true">Active</MenuItem>
                                  <MenuItem value="false">Blocked</MenuItem>
                                </Select>
                                {selectedIndex === id && (
                                  <Popup item={item} path="/question/status" handleClose={handleClose} />
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell style={{ width: '100px' }}>
                              <Iconify
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) => handleEditQuestion(e, item)}
                                icon="clarity:edit-solid"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  showFirstButton
                  showLastButton
                  count={questionCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        </>
      )}
    </Page>
  );
}
