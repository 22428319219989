import { Link, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Box,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { useEffect, useState } from 'react';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/filter/ChatFilter';
import { CustomButton } from '../theme/CustomButton';
// import Popup from '../popups/JobPostStatusChange';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Chat() {
  const [emailData, setEmailData] = useState();
  const [pages, setPages] = useState(0);
  const [emailDataCount, setEmailDataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);

  const getEmailDocs = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/maildata/all/chat`, {
        params: options,
      });
      console.log('emailDataDocs chat', res);
      setEmailData(res.data.data.emailDataDocs);
      setEmailDataCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getEmailDocs(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(emailDataCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getEmailDocs(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.id.length) {
      temp.id = filter.id;
    }
    if (filter.fullname.length) {
      temp.fullname = filter.fullname;
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.phone.length) {
      temp.phone = filter.phone;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    setOptions(temp);
    setPages(0);
    getEmailDocs(temp);
  };

  useEffect(() => {
    getEmailDocs(options);
  }, []);

  return (
    <Page title="Chat">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
           <Typography variant="h4" mb={4}>Chats</Typography>
          </Stack>
          <Card>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} padding={2}>
                <TextField
                  size="small"
                  style={{ maxWidth: '100px', maxHeight: '35px' }}
                  type="number"
                  component="div"
                  label="Go to"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                  Go
                </CustomButton>
              </Stack>
              <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                <Filter applyFilters={applyFilters} />
              </Stack>
            </Stack>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Record ID</TableCell>
                      <TableCell>Fullname</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emailData?.map((item, id) => (
                      <TableRow key={id}>
                        <TableCell style={{ width: '40px' }}>{pages * size + (id + 1)}</TableCell>
                        <TableCell style={{ width: '80px', cursor: 'pointer' }}>
                          <Stack direction="column" spacing={0.5} width="80px">
                            <CopyToClipboard text={item._id} onCopy={() => toast.info('Record ID copied')}>
                              <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                <Iconify
                                  icon="lucide:clipboard-copy"
                                  style={{ height: '18px', width: '18px', color: '#192B6B' }}
                                />
                                <Typography sx={{ color: '#192B6B' }} variant="p">
                                  Copy ID
                                </Typography>
                              </Stack>
                            </CopyToClipboard>
                          </Stack>
                        </TableCell>
                        <TableCell>{item?.fullname?.toUpperCase()}</TableCell>
                        <TableCell>{item?.email}</TableCell>
                        <TableCell>{item?.phone}</TableCell>
                        <TableCell> {Moment(item.createdAt).format('DD-MMM-YYYY')}</TableCell>
                        <TableCell>
                          <Box>
                            <Link to={`/dashboard/chat/view/${item._id}`}>
                              <Icon sx={{ color: 'gray' }}>
                                <Iconify icon="subway:eye" />
                              </Icon>
                            </Link>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[]}
              showFirstButton
              showLastButton
              component={'div'}
              count={emailDataCount}
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Card>
        </Container>
      )}
    </Page>
  );
}
