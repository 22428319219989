import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root ': {
    borderRadius: 2,
    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
    fontFamily: 'Roboto',
  },
});

const CssButton = styled(LoadingButton)({
  '&.MuiButtonBase-root': {
    borderRadius: '5px',
    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
    fontFamily: 'Roboto',
    background: 'linear-gradient(89.99deg, #008ED4 0.01%, #0069CD 100.14%)',
  },
});
export default function LoginForm() {
  const navigate = useNavigate();
  const uninterceptedAxiosInstance = axios.create();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      admin: false,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        let loginUrl = `${process.env.REACT_APP_API_URL}/auth/employee/login`;
        if (values.admin) {
          loginUrl = `${process.env.REACT_APP_API_URL}/auth/admin/login`;
        }
        const res = await uninterceptedAxiosInstance.post(loginUrl, values);
        if (res.data.isError) {
          console.log(res.data.message);
          toast.error(res.data.message);
        } else {
          localStorage.setItem('accessToken', res.data.data.accessToken);
          localStorage.setItem('profile', JSON.stringify(res.data.data));
          let navigateTo = '/dashboard/app';
          if (['SALES_STAFF', 'SALES_MANAGER'].includes(res.data.data.role)) {
            navigateTo = '/dashboard/crm/leads';
          }
          navigate(navigateTo, { replace: true });
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} marginBottom={4}>
          <CssTextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <CssTextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="start" sx={{ my: 2, width: '100%' }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('admin')} checked={values.admin} />}
            label="Login as Admin"
          />

          {/* <Typography
            sx={{ fontSize: '18px', cursor: 'pointer', color:"#07ADBB" }}
            onClick={() => setForgotPass(true)}
          >
            Forgot password?
          </Typography> */}
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            Forgot password?
          </Link>
        </Stack> */}

        <CssButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </CssButton>
      </Form>
    </FormikProvider>
  );
}
