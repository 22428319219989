import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import { FilePond, File, registerPlugin } from 'react-filepond';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';

/// editor //
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Page from '../components/Page';
import Iconify from '../components/Iconify';
import PageHeader from '../components/smallComponents/PageHeader';

export default function ViewJobPost() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [descriptionError, setDescriptionError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState();
  const [jobPost, setJobPost] = useState({});
  const [description, setDescription] = useState('');
  const [open1, setOpen1] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/category/designation/active`)
      .then((res) => setMenu(res.data.data.categories))
      .catch((err) => console.log(err));
  }, []);
  const FILE_SIZE = 1 * 1024 * 1024;
  // const SUPPORTED_FORMATS = ['jpg'];

  const blogSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    jobType: Yup.string().required('Type is required'),
    experience: Yup.string().required('Experience is required'),
    qualification: Yup.string().required('Qualification is required'),
    description: Yup.string().required('Description is required').min(9, 'Description is required'),
    priority: Yup.string().required('Priority is required'),
    // assignment: Yup.mixed().test('fileSize', 'File ', (value) => value && value.size <= FILE_SIZE),
    // .test('fileFormat', 'Unsupported Format', (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });

  const formik = useFormik({
    initialValues: {
      title: jobPost?.title,
      jobType: jobPost?.jobType,
      description: '',
      experience: jobPost?.summary?.experience,
      assignment: '',
      qualification: jobPost?.summary?.qualification,
      id,
      priority: jobPost?.priority ? jobPost?.priority.toString() : '0',
    },
    // validationSchema: blogSchema,
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/jobpost/`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast(res.data.message);
        navigate('/dashboard/job');
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const getJobPost = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/jobpost`, { params: { id } });
      console.log('jobPost', res);
      setJobPost(res.data.data.jobPost);
      setDescription(res.data.data.jobPost.description);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const deletePost = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/jobpost/delete`, { id });
      navigate(-1);
      toast(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const closeAddCategoryModal = () => {
    setOpen1(false);
    toast();
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    formik.setFieldValue('description', editorData);
    if (editorData.length > 8) {
      formik.setFieldError('description', null);
    } else {
      formik.setFieldError('description', 'Required');
    }
  }, [editorState]);

  useEffect(() => {
    getJobPost();
  }, []);

  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(`${description}`))));
  }, [description]);

  return (
    <Page title="View Job">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Delete job Dialog */}
          <Dialog open={open1}>
            <DialogTitle>Delete Job?</DialogTitle>
            <Grid container paddingBottom={'10px'} Width="500px">
              <DialogContent paddingTop="20px">
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction={'column'} spacing={1} width="500px">
                    <Stack direction="row" justifyContent="space-between">
                      Are you sure you want to delete this job?
                    </Stack>
                  </Stack>
                </Grid>
                <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                  <Button onClick={closeAddCategoryModal}>No</Button>
                  <Button type="submit" onClick={deletePost}>
                    Yes
                  </Button>
                </Stack>
              </DialogContent>
            </Grid>
          </Dialog>
          {/* Delete job dialog end */}
          <Container maxWidth="xl">
            <Card
              sx={{
                boxShadow: '#6E8AEE 0px 1px 4px',
                borderRadius: '10px',
              }}
            >
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" padding={1}>
                <PageHeader title="View Job Posts" />
                <Stack direction={'row'} spacing={2} justifyContent="space-between" alignItems="center" padding={1}>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    endIcon={<Iconify icon="fluent:delete-32-filled" />}
                    onClick={() => setOpen1(true)}
                  >
                    Delete Job
                  </Button>
                  <Iconify
                    style={{ cursor: 'pointer', fontSize: '20px' }}
                    onClick={() => navigate(-1)}
                    icon="fe:close"
                  />
                </Stack>
              </Stack>
              <Box sx={{ paddingX: 1, paddingY: 2 }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={10} md={9} sm={12}>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            select
                            label="Category"
                            fullWidth
                            defaultValue={jobPost?.title}
                            sx={{ width: '100%' }}
                            {...getFieldProps('title')}
                          >
                            {menu
                              ?.filter((item) => item.name !== jobPost.title)
                              .map((menuItem, key) => (
                                <MenuItem key={key} value={menuItem._id}>
                                  {menuItem.name}
                                </MenuItem>
                              ))}
                            <MenuItem value={jobPost?.title}>{jobPost.title}</MenuItem>
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} lg={2}>
                        <TextField
                          type="text"
                          select
                          fullWidth
                          label="Job Type"
                          defaultValue={jobPost.jobType}
                          sx={{ width: '100%' }}
                          onChange={(e) => formik.setFieldValue('jobType', e.target.value)}
                        >
                          <MenuItem value="INTERNSHIP">Internship</MenuItem>
                          <MenuItem value="FULL_TIME">Full Time</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack>
                          <Typography variant="h6">Job Summary</Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} lg={5} md={6} sm={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          type="text"
                          label="Experience"
                          fullWidth
                          defaultValue={jobPost?.summary?.experience}
                          sx={{ width: '100%' }}
                          {...getFieldProps('experience')}
                        />
                      </Grid>
                      <Grid item xs={12} lg={5} md={12} sm={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          type="text"
                          label="Qualification"
                          fullWidth
                          defaultValue={jobPost?.summary?.qualification}
                          sx={{ width: '100%' }}
                          {...getFieldProps('qualification')}
                        />
                      </Grid>
                      <Grid item xs={8} lg={2} md={3} sm={4}>
                        <TextField
                          select
                          InputLabelProps={{ shrink: true }}
                          label="Priority"
                          fullWidth
                          defaultValue={jobPost?.priority ? jobPost?.priority.toString() : '0'}
                          sx={{ width: '100%' }}
                          error={Boolean(touched.priority && errors.priority)}
                          helperText={touched.priority && errors.priority}
                          onChange={(e) => formik.setFieldValue('priority', e.target.value)}
                        >
                          <MenuItem value={'0'}>No Priority</MenuItem>
                          <MenuItem value={'1'}>Low</MenuItem>
                          <MenuItem value={'2'}>Medium</MenuItem>
                          <MenuItem value={'3'}>High</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} direction="column">
                        <Typography variant="h6">Description</Typography>
                        <Box sx={{ minHeight: '200px', border: 'solid 1px #e6e6e6', borderRadius: '10px' }} padding={1}>
                          <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            onBlur={() => setDescriptionError(true)}
                          />
                        </Box>
                        {errors.description && descriptionError && (
                          <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                            <Typography variant="caption" color="red">
                              Description is required
                            </Typography>
                          </Stack>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          type="file"
                          label="Assignment"
                          InputLabelProps={{ shrink: true }}
                          name="assignment"
                          onChange={(event) => formik.setFieldValue('assignment', event.target.files[0])}
                          // error={Boolean(touched.assignment && errors.assignment)}
                          // helperText={touched.assignment && errors.assignment}
                        />
                      </Grid>

                      {jobPost.file?.map((item, key) => {
                        return (
                          <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
                            <Stack
                              alignItems="center"
                              direction="row"
                              justifyContent="space-between"
                              sx={{
                                borderRadius: '5px',
                                paddingLeft: 1,
                                border: '1px solid #C4D0FD',
                                height: '100%',
                              }}
                            >
                              <Stack direction="column" justifyContent="center" sx={{ height: '100%', padding: 1 }}>
                                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{item.name}</Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center" sx={{ height: '100%' }} padding={1}>
                                <Iconify
                                  onClick={() => window.open(item.file, '_blank')}
                                  icon="carbon:task-view"
                                  style={{ height: '30px', width: '30px', color: '#192B6B', cursor: 'pointer' }}
                                />
                              </Stack>
                            </Stack>
                          </Grid>
                        );
                      })}
                    </Grid>

                    <Grid item xs={12}>
                      <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          onClick={() => setDescriptionError(true)}
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </Form>
                </FormikProvider>
              </Box>
            </Card>
          </Container>
        </>
      )}
    </Page>
  );
}
