import { Stack, Typography } from '@mui/material';
import React from 'react';
import NavigateBack from './NavigateBack';

function PageHeader({ title }) {
  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 2 }} spacing={{ xs: 0.5, sm: 1.5 }}>
      <NavigateBack />
      <Typography
        variant="h4"
        sx={{
          // show in single line and ellipsis
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          // show in single line and ellipsis
        }}
      >
        {title}
      </Typography>
    </Stack>
  );
}

export default PageHeader;
