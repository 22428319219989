import { useState } from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Stack, Box, MenuItem } from '@mui/material';
import Iconify from './Iconify';
import { CustomButton } from '../theme/CustomButton';

const Filter = ({ applyFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                candidateId: '',
                jobExpoId: '',
                questionPaperId: '',
                startDate: '',
                endDate: '',
                isBlocked: 'ALL',
                isFinished: 'ALL',
                securedMarks: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, handleChange }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack
                    spacing={1.5}
                    direction="column"
                    sx={{ minWidth: { lg: '400px', md: '400px', sm: '400px', xl: '400px' } }}
                  >
                    <TextField
                      fullWidth
                      id="candidateId"
                      name="candidateId"
                      label="Candidate Id"
                      value={values.candidateId}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      id="jobExpoId"
                      name="jobExpoId"
                      label="Job Expo ID"
                      value={values.jobExpoId}
                      onChange={handleChange}
                    />
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      <TextField
                        fullWidth
                        id="questionPaperId"
                        name="questionPaperId"
                        label="Question Paper ID"
                        value={values.questionPaperId}
                        onChange={handleChange}
                      />
                      <TextField
                        fullWidth
                        id="securedMarks"
                        name="securedMarks"
                        label="Secured Marks"
                        value={values.securedMarks}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      <TextField
                        fullWidth
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        type="date"
                        value={values.startDate}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        type="date"
                        value={values.endDate}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Stack>
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      <TextField
                        select
                        id="isBlocked"
                        name="isBlocked"
                        fullWidth
                        label="Status"
                        value={values.isBlocked}
                        onChange={handleChange}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="false">ACTIVE</MenuItem>
                        <MenuItem value="true">BLOCKED</MenuItem>
                      </TextField>
                      <TextField
                        fullWidth
                        select
                        id="isFinished"
                        name="isFinished"
                        label="Finished"
                        value={values.isFinished}
                        onChange={handleChange}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="false">NOT FINISHED</MenuItem>
                        <MenuItem value="true">FINISHED</MenuItem>
                      </TextField>
                    </Stack>

                    <CustomButton variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </CustomButton>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
