import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function AddCandidate({ open, setOpen, handleClose, callApi }) {
  const [jobExpos, setJobExpos] = React.useState([]);

  const getJobExpos = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/jobExpo/active`);
      console.log(res.data.data.records);
      setJobExpos(res.data.data.records);
    } catch (error) {
      console.log(error);
    }
  };

  const candidateSchema = Yup.object().shape({
    jobExpoId: Yup.string().required('Job Expo is required'),
    fullname: Yup.string().required('Full Name is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    email: Yup.string().required('Email is required'),
    isBlocked: Yup.string().required('Is Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      jobExpoId: '',
      fullname: '',
      phoneNumber: '',
      email: '',
      isBlocked: 'false',
    },
    validationSchema: candidateSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/events/candidate`, formik.values);
        toast(res.data.message);
        callApi();
        setOpen(false);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  React.useEffect(() => {
    getJobExpos();
  }, []);

  return (
    <Dialog open={open}>
      <DialogTitle>Add Job Expo</DialogTitle>{' '}
      <DialogContent paddingTop="20px">
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={1} width="500px" marginY={2}>
              <Stack direction="row" justifyContent="space-between">
                <Autocomplete
                  id="jobExpoId"
                  options={jobExpos} 
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, value) => option._id === value._id}
                  value={values.jobExpoId}
                  onChange={(_, value) => {
                    formik.setFieldValue('jobExpoId', value._id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth 
                      label="Job Expo"
                      error={Boolean(touched.jobExpoId && errors.jobExpoId)}
                      helperText={touched.jobExpoId && errors.jobExpoId}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <TextField
                  fullWidth
                  label="Full Name"
                  placeholder="Full Name"
                  {...getFieldProps('fullname')}
                  error={Boolean(touched.fullname && errors.fullname)}
                  helperText={touched.fullname && errors.fullname}
                />
                <TextField
                  fullWidth
                  label="Phone Number"
                  placeholder="Phone Number"
                  {...getFieldProps('phoneNumber')}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <TextField
                  fullWidth
                  label="Email"
                  placeholder="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  select
                  label="Status"
                  placeholder="Status"
                  {...getFieldProps('isBlocked')}
                  error={Boolean(touched.isBlocked && errors.isBlocked)}
                  helperText={touched.isBlocked && errors.isBlocked}
                >
                  <MenuItem value={'false'}>Active</MenuItem>
                  <MenuItem value={'true'}>Blocked</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <LoadingButton variant="contained" onClick={handleSubmit} loading={isSubmitting}>
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
