import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { useEffect, useState } from 'react';
// @mui
import { Card, CardHeader, Box, Stack, Typography, TextField, MenuItem, Button } from '@mui/material';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

BarGraph.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  xaxisPlot: PropTypes.string,
  unit: PropTypes.string,
  callAPI: PropTypes.func,
};

export default function BarGraph({
  title,
  subheader,
  chartLabels,
  chartData,
  xaxisPlot,
  unit,
  callAPI,
  hideOptions,
  ...other
}) {
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [grphType, setGrphType] = useState('DAY');

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: 30, ditributed: true } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: xaxisPlot }, 
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} ${unit} `;
          }
          return y;
        },
      },
    },
  });

  useEffect(() => {
    if (callAPI) {
      callAPI(startDate, endDate, grphType);
    }
  }, []);

  useEffect(() => {
    if (grphType === 'DAY') {
      setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
    } else if (grphType === 'WEEK') {
      setStartDate(moment().subtract(7, 'weeks').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
    } else if (grphType === 'MONTH') {
      setStartDate(moment().subtract(7, 'months').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
    } else if (grphType === 'YEAR') {
      setStartDate(moment().subtract(7, 'years').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
    }
  }, [grphType]);

  return (
    <Card {...other}>
      <Stack direction={'row'} justifyContent={'space-between'} sx={{ mx: 3, my: 3, flexWrap: 'wrap' }}>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        {!hideOptions && (
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={3}>
            <TextField
              id="startDate"
              size="small"
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => { 
                setStartDate(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="endDate"
              label="End Date"
              size="small"
              type="date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="graphType"
              size="small"
              label="Graph Type"
              select
              value={grphType}
              onChange={(e) => {
                setGrphType(e.target.value);
              }}
            >
              <MenuItem value="DAY">Day</MenuItem>
              <MenuItem value="WEEK">Week</MenuItem>
              <MenuItem value="MONTH">Month</MenuItem>
              <MenuItem value="YEAR">Year</MenuItem>
            </TextField>
            <Button
              variant="contained"
              onClick={() => {
                callAPI(startDate, endDate, grphType);
              }}
            >
              Submit
            </Button>
          </Stack>
        )}
      </Stack>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
