import { Link, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Box,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { useEffect, useState } from 'react';
import hasAccessOf from '../store';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Filter from '../components/ResumeFilter';
import Popup from '../components/popups/JobPostStatusChange';
import { CustomButton } from '../theme/CustomButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Resumes() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 0;
  const size = searchParams.get('size') ? Number(searchParams.get('size')) : 10;
  const [resumeData, setResumeData] = useState();
  const [resumeCount, setResumeCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState({ size, page });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const navigate = useNavigate();
  const getAllResumes = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/resume/all`, {
        params: options,
      });
      console.log('resumes', res);
      setResumeData(res.data.data.records);
      setResumeCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size: options.size };
    if (filter.resumeId.length) {
      temp.resumeId = filter.resumeId;
    }
    if (filter.status.length) {
      temp.status = filter.status;
    }
    if (filter.fullname.length) {
      temp.fullname = filter.fullname;
    }
    if (filter.designation.length) {
      temp.designation = filter.designation;
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }

    setOptions(temp);
    getAllResumes(temp);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setOptions(temp);
    setIsLoading(true);
    getAllResumes(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(resumeCount / options.size)) {
      return;
    }
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    setIsLoading(true);
    getAllResumes(temp);
  };

  const handleStatusChange = async (event, id) => {
    console.log(event.target.value);
    try {
      const res = await axios
        .put(`${process.env.REACT_APP_API_URL}/resume/status`, {
          id,
          status: event.target.value,
        })
        .then((res) => {
          if (!res.data.isError) {
            toast.success(`Status Changed to ${event.target.value}`);
            getAllResumes(options);
          }
        });
      console.log('resumes', res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (options.page !== page) {
      if (options.size !== size) {
        setOptions({ ...options, page, size });
        getAllResumes({ ...options, page, size });
      } else {
        setOptions({ ...options, page });
        getAllResumes({ ...options, page });
      }
    } else {
      getAllResumes(options);
    }
  }, [page, size]);

  useEffect(() => {
    if (options.page !== page) {
      setSearchParams({ page: options.page, size: options.size });
    }
    if (options.size !== size) {
      setSearchParams({ size: options.size, page: options.page });
    }
  }, [options.page, options.size]);

  return (
    hasAccessOf('resumes#resume') && (
      <Page title="Resumes">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" mb={4}>
                  Resumes
                </Typography>
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      onKeyDown={(e) => e.key === 'Enter' && goToPage()}
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <CustomButton variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </CustomButton>
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      {/* <JobPostListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Received At</TableCell>
                          <TableCell>Fullname</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Designation</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resumeData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell style={{ width: '80px' }}>{options.page * options.size + (id + 1)}</TableCell>
                            <TableCell style={{ width: '120px' }}>
                              {Moment(item.createdAt).format('DD-MMM-YYYY')}
                            </TableCell>
                            <TableCell style={{ minWidth: '30%' }}>{item.fullname}</TableCell>
                            <TableCell>{item?.email}</TableCell>
                            <TableCell>{item?.category}</TableCell>
                            <TableCell>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  value={item?.status}
                                  onChange={(e) => handleStatusChange(e, item._id)}
                                >
                                  <MenuItem value="AWAITING_REVIEW">AWAITING REVIEW</MenuItem>
                                  <MenuItem value="REVIEWED">REVIEWED</MenuItem>
                                  <MenuItem value="CONTACTING">CONTACTING</MenuItem>
                                  <MenuItem value="REJECTED">REJECTED</MenuItem>
                                  <MenuItem value="HIRED">HIRED</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Icon
                                  sx={{ color: 'gray' }}
                                  onClick={() => window.open(`/dashboard/resumes/view/${item._id}`, '_blank')}
                                >
                                  <Iconify icon="subway:eye" />
                                </Icon>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  onRowsPerPageChange={(e) => {
                    setOptions({ ...options, size: Number(e.target.value), page: 0 });
                    getAllResumes({ ...options, size: Number(e.target.value) });
                  }}
                  component={'div'}
                  count={resumeCount}
                  onPageChange={handlePageChange}
                  showFirstButton
                  showLastButton
                  rowsPerPage={options.size}
                  page={options.page}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    )
  );
}
